import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { EvaluationService } from "../services/evaluation.service";
import { PdfService } from "../services/pdfService";
import { Evaluation } from "../model/Evaluation";
import { SnackBarService } from "../services/snack-bar.service";

@Component({
  selector: "app-organization-creator",
  templateUrl: "./tokens.component.html",
  styleUrls: ["./tokens.component.css"],
})
export class TokensComponent {
  evaluationId: string | null = null;
  evaluation: Evaluation | null = null;
  tokens: Array<any> | null = null;

  constructor(
    private route: ActivatedRoute,
    public evaluationService: EvaluationService,
    public pdfService: PdfService,
    private snackbarService: SnackBarService,
  ) {}

  ngOnInit() {
    this.evaluationId = this.route.snapshot.paramMap.get("evaluationId");
    if (this.evaluationId) {
      this.evaluationService
        .getEvaluationById(this.evaluationId)
        .subscribe((result) => {
          this.evaluation = result;
        });
    }
  }

  generatePdfBasedOnResult(result: any, facility: any) {
    this.pdfService.generateTokensPDF({
      logo: this.evaluation?.logo || "",
      tokens: result,
      evaluationId: this.evaluationId as string,
      evaluationDescription: this.evaluation?.description || "",
      facilityName: facility.facility_name,
      tokenDictionary: this.evaluation?.tokenDictionary,
      descriptionPdf: this.evaluation?.descriptionPdf || "",
      devicesDescriptionPdf: this.evaluation?.devicesDescriptionPdf || "",
      entryDescriptionPdf: this.evaluation?.entryDescriptionPdf || "",
      editingPdf: this.evaluation?.editingPdf || "",
      durationMinutesPdf: this.evaluation?.durationMinutesPdf || "",
      resultsPdf: this.evaluation?.resultsPdf || "",
      afterQRCodeDescriptionPdf:
        this.evaluation?.afterQRCodeDescriptionPdf || "",
      fullNamePdf: this.evaluation?.fullNamePdf || "",
      personPositionPdf: this.evaluation?.personPositionPdf || "",
      farewellTextPdf: this.evaluation?.farewellTextPdf || "",
      contactPersonPdf: this.evaluation?.contactPersonPdf || "",
      startdate: this.evaluation?.startdate.toString(),
      enddate: this.evaluation?.enddate.toString(),
    });
  }

  generatePDF(facility: any, action = "open") {
    //@TODO: rething this approach
    this.evaluationService
      .getTokens(this.evaluationId || "2", facility.facility_id)
      .subscribe(
        (result) => {
          if (result?.length && this.evaluationId) {
            this.generatePdfBasedOnResult(result, facility);
          } else {
            this.evaluationService
              .generateTokens(Number(this.evaluationId), facility.facility_id)
              .subscribe(
                (resultGeneration) => {
                  if (resultGeneration?.length && this.evaluationId) {
                    this.generatePdfBasedOnResult(resultGeneration, facility);
                  }
                },
                () => {
                  this.snackbarService.error("Failed to generate tokens");
                },
              );
          }
        },
        //@TODO very strange code, needs to be reimplemented
        () => {
          this.snackbarService.error("Failed to download pdf");
        },
      );
  }

  protected readonly String = String;
}
