import { Injectable } from "@angular/core";
import { Observable, of, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Evaluation } from "../model/Evaluation";
import { EvaluationServiceInterface } from "../interfaces/EvaluationServiceInterface";
import { Survey } from "knockout/kosurvey";
import { ContextService } from "./context.service";
import { Token } from "../model/Token";
import { map } from "rxjs/operators";
import { Title } from "../model/Title";
import { JwtHelperService } from "@auth0/angular-jwt";

/**
 * Copyright (C) 2016 - 2021 oparco - open architectures & consulting
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains the property
 * of oparco - open architectures & consulting and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary
 * to oparco - open architectures & consulting and its suppliers and may be
 * covered by foreign Patents, patents in process, and are protected by
 * trade secret or copyright law. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from oparco - open architectures & consulting.
 */

@Injectable({
  providedIn: "root",
})
export class EvaluationService implements EvaluationServiceInterface {
  private readonly mockedOptionsForRange = [
    {
      value: "-1",
      title: {
        ru: "Не отвечено",
        de: "Nicht beantwortet",
        en: "Not answered",
        uk: "Не відповідено",
        ar: "لم يتم الرد",
      },
    },
    {
      value: "1",
      title: {
        ru: "sehr unzufrieden",
        de: "sehr unzufrieden",
        en: "sehr unzufrieden",
        uk: "sehr unzufrieden",
        ar: "sehr unzufrieden",
      },
    },
    {
      value: "2",
      title: {
        ru: "sehr unzufrieden",
        de: "sehr unzufrieden",
        en: "sehr unzufrieden",
        uk: "sehr unzufrieden",
        ar: "sehr unzufrieden",
      },
    },
    {
      value: "3",
      title: {
        ru: "zufrieden",
        de: "zufrieden",
        en: "zufrieden",
        uk: "zufrieden",
        ar: "zufrieden",
      },
    },
    {
      value: "4",
      title: {
        ru: "gut",
        de: "gut",
        en: "gut",
        uk: "gut",
        ar: "gut",
      },
    },
    {
      value: "5",
      title: {
        ru: "gut",
        de: "gut",
        en: "gut",
        uk: "gut",
        ar: "gut",
      },
    },
    {
      value: "6",
      title: {
        ru: "herausragend",
        de: "herausragend",
        en: "herausragend",
        uk: "herausragend",
        ar: "herausragend",
      },
    },
  ];
  constructor(
    private http: HttpClient,
    private context: ContextService,
    private jwtHelper: JwtHelperService,
  ) {}

  evaluationServiceURL = "evaluationService";

  private evaluationCache: { [key: string]: Evaluation } = {};
  private evaluationSummaryCache: { [key: string]: Record<string, string> } =
    {};

  public getQuestionsWithOpenAnswers(newSurvey: any) {
    const currentLanguage = localStorage.getItem("lang") || "de";
    if (!currentLanguage) return {};
    return {
      logoPosition: "right",
      title: newSurvey?.title,
      pages: newSurvey?.pages?.map((page) => ({
        name: page?.title?.[currentLanguage],
        id: page?.id,
        elements: page?.questions
          ?.filter((question) => question?.type === "text")
          .map((question) => ({
            type: "text",
            name: String(question?.id),
            title: question?.indicator,
            defaultValue: "Not answered",
          })),
      })),
    };
  }

  private mapTitles(titlesRaw: Array<Title>) {
    return titlesRaw?.reduce((acc, item) => {
      return { ...acc, [item?.lang]: item?.description };
    }, {});
  }

  private mapEntityTitles(titlesRaw: Array<any>) {
    return titlesRaw.reduce((acc, title) => {
      acc[title.type] = acc[title.type] || {};
      acc[title.type][title.lang] = title.description;
      return acc;
    }, {});
  }

  public mapEntityTitlesReverse(entity: any) {
    return Object.keys(entity)?.map((key) => ({
      lang: key,
      description: entity?.[key],
    }));
  }

  public mapEntityTokenTypeTitles(data: any) {
    let result: Array<{ lang: string; description: string; type: string }> = [];
    for (let type in data) {
      for (let lang in data?.[type]) {
        result.push({
          lang: lang,
          description: data?.[type][lang],
          type: type,
        });
      }
    }
    return result;
  }

  public mapCustomTokenTypeTitles(customTokenTypeTitles: Array<any>) {
    return customTokenTypeTitles?.reduce((acc, title) => {
      acc[title?.tokenType] = title?.customTitle;
      return acc;
    }, {});
  }

  private mapCustomSurvey(customSurvey: any) {
    const result = {
      ...customSurvey,
      pages: customSurvey?.pages?.map((page) => ({
        id: page?.id,
        title: this.mapTitles(page?.titles),
        questions: page?.questions?.map((question) => ({
          id: question?.id,
          type: question?.type?.toLowerCase(),
          indicator: question?.indicator,
          title: this.mapEntityTitles(question?.titles),
          tip: this.mapTitles(question?.tips),
          options: [
            ...question?.options?.map((option) => ({
              id: option?.id,
              value: option?.value,
              title: this.mapTitles(option.titles),
            })),
            {
              value: "-1",
              title: {
                ru: "Не отвечено",
                de: "Nicht beantwortet",
                en: "Not answered",
                uk: "Не відповідено",
                ar: "لم يتم الرد",
              },
            },
          ],
        })),
      })),
    };
    const token = localStorage.getItem("access_token");
    if (!token) return result;
    const decodedToken = this.jwtHelper.decodeToken(String(token)) || null;
    if (decodedToken?.role === "admin" || !decodedToken) return result;
    if (decodedToken?.role === "user") {
      const filteredResultPages = result?.pages
        ?.map((page) => {
          return {
            ...page,
            questions: page?.questions?.filter(
              (question) => question?.title[decodedToken?.type.toUpperCase()],
            ),
          };
        })
        ?.filter((page) => page?.questions?.length > 0);

      result.pages = filteredResultPages;
    }

    return result;
  }

  public mapOldSurvey(newSurvey: any) {
    const currentLanguage = localStorage.getItem("lang") || "de";
    if (!currentLanguage) return {};
    return {
      logoPosition: "right",
      title: newSurvey?.title,
      pages: newSurvey?.pages?.map((page) => ({
        name: page?.title?.[currentLanguage],
        // elements: page?.questions?.filter((question) => question?.type !== "text").map((question) => {
        elements: page?.questions?.map((question) => {
          const title = question?.title?.["TEAM"]?.[currentLanguage];
          const titleArray = title.split(" ");
          //@TODO rethink, there will be more
          // const type = question?.type === "radio" ? "radiogroup" : "text";

          let type;

          if (question?.type === "radio" || question?.type === "range") {
            type = "radiogroup";
          } else {
            type = "text";
          }
          const MAX_WORDS_PER_LINE = 20;
          const AVERAGE_WORD_LENGTH = 6;
          const BIG_WORD_LENGTH = 10;
          let currentWords = 0;

          const increaseWordsCount = (text: string) => {
            if (text.length <= AVERAGE_WORD_LENGTH) {
              currentWords++;
              return;
            }
            if (text.length > BIG_WORD_LENGTH) {
              currentWords += 3;
              return;
            }

            if (text.length > AVERAGE_WORD_LENGTH) {
              currentWords += 2;
              return;
            }
          };

          const newTitle = titleArray
            .reduce((acc, curr, index) => {
              if (index === 0) {
                acc.push("<br>");
              }

              increaseWordsCount(curr);

              if (currentWords >= MAX_WORDS_PER_LINE) {
                acc.push("<br>");
                currentWords = 0;
              }

              acc.push(curr);

              return acc;
            }, [])
            .join(" ");
          const result = {
            type,
            name: String(question?.id),
            title: `<span style="margin-bottom: -20px !important;">${question?.indicator}</span> <sub 
                        style="font-size: 10px !important; line-height: 8px !important; padding: 0 20px 100px !important;"
                        >${newTitle}</sub>`,
            defaultValue: "Not answered",
            choices: (question?.type === "range"
              ? this.mockedOptionsForRange
              : question?.options
            )?.map((option) => {
              const optionText = option?.title?.[currentLanguage];

              const insertBreaks = (text) => {
                if (!text) return "";
                const words = text.split(" ");
                let currentLineLength = 0;
                return words
                  .reduce((acc, word, index) => {
                    if (
                      currentLineLength + word.length + (index > 0 ? 1 : 0) >
                      25
                    ) {
                      acc.push("<br>");
                      currentLineLength = 0;
                    }
                    acc.push(word);
                    currentLineLength += word.length + 1;
                    return acc;
                  }, [])
                  .join(" ");
              };

              const formattedText = insertBreaks(optionText);

              return {
                value: option?.value,
                text: `<span style="font-size: ${formattedText.length > 25 ? "7.5px !important" : "8px !important"} ; line-height: 4px">${formattedText}</span>`,
              };
            }),
          };
          return result;
        }),
      })),
    };
  }

  private mapEvaluation(rawEvaluation: any): Evaluation {
    return {
      ...rawEvaluation,
      tokenDictionary: this.mapCustomTokenTypeTitles(
        rawEvaluation?.customTokenTypeTitles,
      ),
      customSurvey: rawEvaluation?.customSurvey && {
        ...this.mapCustomSurvey(rawEvaluation?.customSurvey),
      },
    };
  }

  public getAllExistingEvaluations(): Observable<Evaluation[]> {
    return this.http
      .get<Evaluation[]>(
        this.context._configurationEnvironment.service_engine_base_url +
          this.evaluationServiceURL,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      )
      .pipe(
        map((response) => {
          return response.map(this.mapEvaluation.bind(this));
        }),
      );
  }

  public getEvaluationsByType(type: string): Observable<Evaluation[]> {
    return this.http
      .get<Evaluation[]>(
        `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/state/${type}`,
        {
          headers: { Accept: "application/json" },
        },
      )
      .pipe(
        map((response) => {
          return response.map(this.mapEvaluation.bind(this));
        }),
      );
  }

  public getEvaluationById(evaluationId: string): Observable<Evaluation> {
    const cachedEvaluation = this.evaluationCache[evaluationId];

    if (cachedEvaluation) return of(cachedEvaluation);

    return this.http
      .get<Evaluation>(
        `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/${evaluationId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      )
      .pipe(
        // tap(response => {
        //     this.evaluationCache[evaluationId] = response;
        // }),
        map((response) => {
          return this.mapEvaluation.call(this, response);
        }),
      );
  }

  public getCurrentActiveEvaluations(): Observable<Evaluation[]> {
    return this.http
      .get<Evaluation[]>(
        `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      )
      .pipe(
        map((response) => {
          return response.map(this.mapEvaluation.bind(this));
        }),
      );
  }

  public getEvaluationSummaryById(
    evaluationId: string,
    facilityIds?: Array<number | string>,
    entities?: Array<string>,
  ): Observable<any> {
    return this.http
      .post<any>(
        `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/survey/${evaluationId}/getEvaluationSummary`,
        JSON.stringify({ facilityIds, entities }),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      )
      .pipe(
        tap((response) => {
          this.evaluationSummaryCache[evaluationId] = response;
        }),
      );
  }

  public createEvaluation(): Observable<Evaluation> {
    return Observable.create(new Evaluation());
  }

  public updateEvaluation(): Observable<Evaluation> {
    return Observable.create(new Evaluation());
  }

  public activateEvaluation(): Observable<Evaluation> {
    return Observable.create(new Evaluation());
  }

  public deActivateEvaluation(): Observable<Evaluation> {
    return Observable.create(new Evaluation());
  }

  public readSurveyOfEvaluation(id: number): Observable<any> {
    return this.http.get<String>(
      `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/survey/${id}`,
      {
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "application/octet-stream",
        },
        responseType: "text" as "json",
      },
    );
  }

  public readSurveyDraftOfEvaluation(id: string): Observable<any> {
    return this.http.get<String>(
      `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/surveyDraft/${id}`,
      {
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "application/octet-stream",
        },
        responseType: "text" as "json",
      },
    );
  }

  public readCustomSurveyOfEvaluation(id: number): Observable<any> {
    return this.http
      .get<String>(
        `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/customSurvey/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      )
      .pipe(
        map((response) => {
          return this.mapCustomSurvey(response);
        }),
      );
  }

  public saveSurveyOfEvaluationFinalResult(
    id: number,
    survey: Survey,
  ): Observable<any> {
    return this.http.post<any>(
      `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/survey/${id}/uploadFinalResult`,
      JSON.stringify(survey),
      {
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "application/octet-stream",
        },
        responseType: "text" as "json",
      },
    );
  }

  public saveSurveyOfEvaluationIntermediateResult(
    id: number,
    survey: Survey,
  ): Observable<any> {
    return this.http.post<any>(
      `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/survey/${id}/uploadIntermediateResult`,
      JSON.stringify(survey),
      {
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "application/octet-stream",
        },
        responseType: "text" as "json",
      },
    );
  }

  public getIntermediateResult(id: number): Observable<any> {
    return this.http.get<String>(
      `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/survey/${id}/getIntermediateResult`,
      {
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "application/octet-stream",
        },
        responseType: "text" as "json",
      },
    );
  }

  public uploadSurvey(id: number, survey: any): Observable<any> {
    return this.http.post<any>(
      `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/survey/${id}`,
      survey,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        responseType: "text" as "json",
      },
    );
  }

  public uploadSurveyDraft(id: string, surveyDraft: string): Observable<any> {
    return this.http.post<any>(
      `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/surveyDraft/${id}`,
      surveyDraft,
      {
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "application/octet-stream",
        },
        responseType: "text" as "json",
      },
    );
  }

  public getEvaluationResults(id: number): Observable<any> {
    return this.http.get<String>(
      `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/survey/${id}/getEvaluationResults`,
      {
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "application/octet-stream",
        },
        responseType: "text" as "json",
      },
    );
  }

  public writeSurveyOfEvaluation(): Observable<String> {
    return Observable.create(new Evaluation());
  }

  public createNewEvaluation(evaluation: string) {
    return this.http
      .post<Evaluation>(
        `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}`,
        evaluation,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      )
      .pipe(
        // tap((response) => {
        //     this.evaluationCache[response?.id] = response;
        // })
        map((response) => {
          return this.mapEvaluation.call(this, response);
        }),
      );
  }

  public updateEvaluation1(id: string, evaluation: string) {
    return this.http
      .put<any>(
        `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/${id}`,
        evaluation,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      )
      .pipe(
        tap((response) => {
          this.evaluationCache[id] = response;
        }),
      );
  }

  public publishEvaluation(id: number): Observable<Evaluation> {
    return this.http.put<any>(
      `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/${id}/publish`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    );
  }

  // public generateTokens(evaluationId: number, facilityId: number) {
  //     return this.http.get<any>(`${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/${evaluationId}/generateTokens/${facilityId}`,
  //         {
  //             headers: {
  //                 "Content-Type": "application/json",
  //                 "Accept": "application/json"
  //             }
  //         })
  // }

  public generateTokens(evaluationId: number, facilityId: number) {
    return this.http.get<any>(
      `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/${evaluationId}/generateTokens/${facilityId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    );
  }

  public deleteEvaluationById(id: number): Observable<any> {
    return this.http.delete(
      `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    );
  }

  public changeEvaluationState(
    id: string,
    state: string,
  ): Observable<Evaluation> {
    return this.http
      .put<Evaluation>(
        `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/${id}/changeState`,
        JSON.stringify({ state }),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      )
      .pipe(
        tap((response) => {
          this.evaluationCache[id] = response;
        }),
      );
  }

  public getTokens(
    evaluationId: string,
    facilityId: string,
  ): Observable<Token[]> {
    return this.http.get<Token[]>(
      `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/tokens/${evaluationId}/${facilityId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    );
  }
}
