import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { EvaluationService } from "../../../../services/evaluation.service";
import { BehaviorSubject, debounceTime, tap } from "rxjs";
import { Evaluation } from "../../../../model/Evaluation";
import { OrganizationService } from "../../../../services/organization.service";
import { Organization } from "../../../../model/Organization";
import { ResultTestFiltersService } from "../../../../services/result-test-filters.service";

@Component({
  selector: "result-common-info",
  templateUrl: "result-common-info.component.html",
  styleUrls: ["result-common-info.component.css"],
})
export class ResultCommonInfoComponent implements OnInit {
  constructor(
    private evaluationService: EvaluationService,
    private organizationService: OrganizationService,
    public resultTestFilterService: ResultTestFiltersService,
    private cdr: ChangeDetectorRef,
  ) {}

  @Input() evaluationId: string;

  currentEvaluation: BehaviorSubject<Evaluation | null> =
    new BehaviorSubject<Evaluation | null>(null);

  evaluationSummary: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  currentOrganization: BehaviorSubject<Organization | null> =
    new BehaviorSubject<Organization | null>(null);

  tokensCounted: Record<string, number> = {};

  isPending: boolean = false;

  ngOnInit() {
    this.tokensCounted = {
      generatedCustomerTokens: 0,
      generatedEmployeeTokens: 0,
      generatedManagementTokens: 0,
      generatedTeamTokens: 0,
    };

    this.resultTestFilterService.getPendingState().subscribe((pending) => {
      this.isPending = pending;
    });

    this.resultTestFilterService.filters$
      .pipe(
        tap(() => this.resultTestFilterService.setPendingState(true)),
        debounceTime(4000),
      )

      .subscribe((filters) => {
        this.evaluationService
          .getEvaluationSummaryById(
            this.evaluationId,
            filters.facilityIds,
            filters.entities,
          )
          .subscribe(
            (summary) => {
              this.evaluationSummary.next(summary);
              this.resultTestFilterService.setPendingState(false);
              this.cdr.detectChanges();
            },
            () => {
              this.resultTestFilterService.setPendingState(false);
              this.cdr.detectChanges();
            },
          );
      });

    this.evaluationService
      .getEvaluationById(this.evaluationId)
      .subscribe((evaluation) => {
        this.currentEvaluation.next(evaluation);
        this.tokensCounted = evaluation?.facilities?.reduce((acc, facility) => {
          acc["generatedCustomerTokens"] += facility.generatedCustomerTokens;
          acc["generatedEmployeeTokens"] += facility.generatedEmployeeTokens;
          acc["generatedManagementTokens"] +=
            facility.generatedManagementTokens;
          acc["generatedTeamTokens"] += facility.generatedTeamTokens;
          return acc;
        }, this.tokensCounted);
      })
      .add(() => {
        if (this.currentEvaluation.value?.organization_id) {
          this.organizationService
            .getOrganizationById(this.currentEvaluation.value?.organization_id)
            .subscribe((organization) => {
              this.currentOrganization.next(organization);
            });
        }
      });
  }
}
