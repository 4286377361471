import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ContextService } from "./context.service";
import { map } from "rxjs/operators";
import { ResultRequestFilters } from "../model/ResultRequestFilters";

@Injectable({
  providedIn: "root",
})
export class ResultService {
  constructor(
    private http: HttpClient,
    private context: ContextService,
  ) {}

  evaluationServiceURL = "evaluationService";

  private mapSurveyResults(result: any) {
    const newResult = {};
    for (let key in result) {
      newResult[key] = Number(result[key]);
    }
    return result;
  }

  private calculateAverageScores(
    surveyResults: Array<any>,
  ): Record<string, string> {
    const aggregatedResults: Record<string, any> = {};
    surveyResults.forEach((person: Record<string, string>) => {
      for (const [key, value] of Object.entries(person)) {
        if (!aggregatedResults[key]) {
          aggregatedResults[key] = { sum: 0, count: 0, allMinusOne: true };
        }
        if (value !== "-1") {
          aggregatedResults[key].sum += parseInt(value);
          aggregatedResults[key].count += 1;
          aggregatedResults[key].allMinusOne = false;
        }
      }
    });

    const averageResults: Record<string, string> = {};
    for (const [key, { sum, count, allMinusOne }] of Object.entries(
      aggregatedResults,
    )) {
      const res = (sum / count).toFixed(2);
      if (res !== "NaN") {
        averageResults[key] = !allMinusOne ? res : "-1";
      } else {
        averageResults[key] = "Nicht beantwortet";
      }
    }

    return averageResults;
  }

  private mapGeneralResult(result: any, facilities: Array<any>) {
    let finalResult = {};
    if (!facilities?.length) {
      finalResult = { ...result };
    } else {
      Object.keys(result).forEach((key) => {
        finalResult[
          facilities?.find(
            (facility) => facility?.facility_id == key,
          )?.facility_name
        ] = this.calculateAverageScores(result[key]);
      });
    }

    return finalResult;
  }

  public getEvaluationResults(
    id: number,
    entity: string,
    facilityId: string,
  ): Observable<any> {
    return this.http
      .get<String>(
        `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/survey/${id}/getEvaluationResults/${facilityId}/${entity}`,
        {
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "application/octet-stream",
          },
          responseType: "text" as "json",
        },
      )
      .pipe(
        map((response) => {
          const responseParsed = JSON.parse(response as string);
          return responseParsed.map(this.mapSurveyResults.bind(this));
        }),
      );
  }

  public getEvaluationResultsNew(
    id: number,
    filters: ResultRequestFilters,
  ): Observable<any> {
    return this.http
      .post<String>(
        `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/getEvaluationResultsNew/${id}`,
        filters,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/octet-stream",
          },
          responseType: "text" as "json",
        },
      )
      .pipe(
        map((response) => {
          return JSON.parse(response as string);
        }),
      );
  }

  public getEvaluationResultsGeneral(
    id: string,
    facilities: Array<any>,
  ): Observable<any> {
    return this.http
      .get<String>(
        `${this.context._configurationEnvironment.service_engine_base_url}${this.evaluationServiceURL}/survey/${id}/getEvaluationResultsGeneral/`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      )
      .pipe(
        map((response) => {
          return this.mapGeneralResult(response, facilities);
        }),
      );
  }
}
