<div class="container">
    <p>
        <b>IMPRESSUM</b><br>
        <br>
        <b>Anbieterkennzeichnung gemäß den gesetzlichen Bestimmungen</b><br>
        Evalution GmbH<br>
        Breiter Weg 31<br>
        39104 Magdeburg<br>
        Telefon   (+49) 39200 / 183269<br>
        Fax         (+49) 39200 / 185489<br>
        Email: info@evalution.de<br>
        Web:         http://www.evalution.de<br>
        <br>
        Geschäftsführer: Markus Winkler / Klaus Roth <br>
        <br>
        <b>Registergericht</b><br>
        Amtsgericht Stendal<br>
        HRB: XXXXXX<br>
        <br>
        <b>Verantwortlich für den Web-Auftritt</b><br>
        Evalution GmbH<br>
        Breiter Weg 31<br>
        39104 Magdeburg<br>
        Telefon   (+49) 39200 / 183269<br>
        Fax         (+49) 39200 / 185489<br>
        Email: info@evalution.de<br>
        Web:         http://www.evalution.de<br>
        <br>
        <b>Copyright-Hinweis</b><br>
        Alle auf unseren Seiten verwendeten Bilder, Fotos, Logos, Texte etc. unterliegen dem Copyright der Stiftung Evangelische Jugendhilfe St. Johannis Bernburg bzw. von Dritten. Sie dürfen nicht bzw. nur nach Rücksprache mit der Redaktion weiter verwendet werden.
        Rechtlicher Hinweis zur Haftung
        Die Inhalte externer Links werden von uns sorgfältig geprüft. Wir übernehmen dennoch keine Haftung für die Inhalte der mit oder von unserer Website verlinkten externen Seiten. Für die Inhalte dieser Seiten sind ausschließlich deren Anbieter verantwortlich.
        Copyright-Hinweis
        Alle auf unseren Seiten verwendeten Bilder, Fotos, Logos, Texte etc. unterliegen dem Copyright der Stiftung Evangelische Jugendhilfe St. Johannis Bernburg bzw. von Dritten. Sie dürfen nicht bzw. nur nach Rücksprache mit der Redaktion weiter verwendet werden.
        <br><br>
        <b>Rechtlicher Hinweis zur Haftung</b><br>
        Die Inhalte externer Links werden von uns sorgfältig geprüft. Wir übernehmen dennoch keine Haftung für die Inhalte der mit oder von unserer Website verlinkten externen Seiten. Für die Inhalte dieser Seiten sind ausschließlich deren Anbieter verantwortlich.
        <br><br>
    </p>
</div>
