import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { SURVEY } from "./mock-survey";
import { ContextService } from "../services/context.service";
import { Faq, FaqService } from "../services/faqService";
import { Evaluation } from "../model/Evaluation";
import { TranslateModule } from "@ngx-translate/core";
import { MatTabsModule } from "@angular/material/tabs";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatRadioModule } from "@angular/material/radio";
import { MatInputModule } from "@angular/material/input";
import { MatSliderModule } from "@angular/material/slider";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { NgForOf, NgIf } from "@angular/common";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: "survey-preview-dialog-content",
  templateUrl: "./survey-preview-dialog-content.component.html",
  styleUrls: ["./survey-preview-dialog-content.component.scss"],
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatExpansionModule,
    MatRadioModule,
    MatInputModule,
    MatSliderModule,
    MatCheckboxModule,
    MatButtonModule,
    NgForOf,
    NgIf,
    MatOptionModule,
    MatSelectModule,
    FormsModule,
    MatIconModule,
  ],
})
export class SurveyPreviewDialogContentComponent implements OnInit, OnDestroy {
  evaluationLogo: string | null = null;
  language: string = "de"; // hardcode
  tokenType: string;
  resultsForm: FormGroup;
  questionsArr: any[] = [];
  selectedIndex = 0;
  maxPagesNumber = 0;
  defaultValue: string = "-1";
  survey: any = SURVEY;
  panelOpenState = false;
  evaluationTitle: string;
  evaluationDescription: string;
  evaluationId: string;

  defaultLang = "de";
  selected: string;
  selectedToken: string;

  constructor(
    private fb: FormBuilder,
    private contextService: ContextService,
    private route: ActivatedRoute,
    private faqService: FaqService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.evaluationId = this.getEvaluationId();
    this.fillSurveyByFormData();

    if (localStorage.getItem("lang")) {
      this.selected = String(localStorage.getItem("lang"));
    } else {
      this.selected = this.defaultLang;
    }

    this.evaluationLogo = this.data?.evaluationFormData?.logo || null;
    this.evaluationTitle = this.data?.designerFormData?.title;
  }

  ngOnDestroy() {
    this.faqService.faq.next(null);
    localStorage.removeItem("currentEvaluationId");
  }

  getEvaluationId(): any {
    if (this.contextService.activeEvaluation) {
      return this.contextService.activeEvaluation.id;
    } else return Number(this.route.snapshot.paramMap.get("id"));
  }

  fillSurveyByFormData() {
    this.survey = {
      description: {
        ru: "ff",
        de: "ff",
        en: "ff",
        uk: "ff",
        ar: "ff",
      },
      creationDate: new Date().toDateString(),
      pages: this?.data?.designerFormData.pages,
    };

    this.evaluationLogo = null;
    this.evaluationTitle = this.data?.designerFormData?.title;
    this.evaluationDescription =
      this.data?.evaluationFormData?.evaluationDescription || "";

    this.getLanguage();
    this.createQuestionsArray();
    this.createResultsForm();
    this.getAmountOfPages();
    this.getTokenTypeFromToken();
    this.setFaq(this.data?.evaluationFormData?.faq);
  }

  setFaq(evaluation: Evaluation) {
    this.faqService.faq.next(
      evaluation.faq?.reduce((acc, item) => {
        return {
          ...acc,
          [item?.lang]: item?.description,
        };
      }, {}) as Faq,
    );
  }

  get currentTokens() {
    return this.data.formTokens;
  }

  getTokenTypeFromToken() {
    this.tokenType = this.currentTokens[0]?.code;
    this.selectedToken = this.currentTokens[0]?.code;
  }

  getLanguage() {
    this.language = localStorage.getItem("lang") || "de";
  }

  createResultsForm() {
    this.resultsForm = this.fb.group({});
    this.questionsArr.forEach((question, idx) => {
      // if (question.type === "multiselect") {
      //   const formArray = this.fb.array([]);
      //   question.options.forEach(() => formArray.push(new FormControl(false)));
      //   this.resultsForm.addControl(`${idx}`, formArray);
      // } else if (question.type === "text") {
      if (question.type === "text") {
        const formControl = this.fb.control("");
        this.resultsForm.addControl(`${idx}`, formControl);
      } else {
        const formControl = this.fb.control(this.defaultValue);
        this.resultsForm.addControl(`${idx}`, formControl);
      }
    });
  }

  createQuestionsArray() {
    if (this.survey) {
      for (let page of this.survey.pages) {
        this.questionsArr = this.questionsArr.concat(page.questions);
      }
    }
  }

  onCheckboxChange(checked: boolean, id: string, value: string): void {
    const formArray: FormArray = this.resultsForm.get(id) as FormArray;
    if (checked) {
      formArray.push(new FormControl(value));
    } else {
      const index = formArray.value.findIndex((val: string) => val === value);
      if (index !== -1) {
        formArray.removeAt(index);
      }
    }
  }

  onTabChange(event: any) {
    this.selectedIndex = event.index;
  }

  getAmountOfPages() {
    this.maxPagesNumber = this.survey.pages.length;
  }

  selectLanguage() {
    this.language = this.selected;
  }

  selectToken() {
    this.tokenType = this.selectedToken;
  }
}
