import { Component, OnDestroy, OnInit } from "@angular/core";
import { ResultService } from "../../../services/result.service";
import { EvaluationService } from "../../../services/evaluation.service";
import { Model } from "survey-core";
import { ResultTestFiltersService } from "../../../services/result-test-filters.service";
import { BehaviorSubject, debounceTime } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Evaluation } from "../../../model/Evaluation";
import { PdfService } from "../../../services/pdfService";
import { OrganizationService } from "../../../services/organization.service";
import { Organization } from "../../../model/Organization";

export const ENTITIES = ["CUSTOMER", "EMPLOYEE", "MANAGEMENT", "TEAM"];

@Component({
  selector: "app-result-test",
  templateUrl: "./result-test.component.html",
  styleUrls: ["./result-test.component.css"],
})
export class ResultTestComponent implements OnInit, OnDestroy {
  currentLanguage: string = "de";
  categories: any[] = [];
  displayedColumns: string[] = [];
  dataSource: Array<any> = [];
  graphDataAll: any = [];
  filterDataForPdf: {
    facilityNames: Array<string>;
    tokenTypeNames: Array<string>;
  };
  dataSourceOpenAnswers: any = [];
  columnsOpenAnswers: any = [];
  translatedText = "";

  surveyWithOpenQuestions: Model;

  isPdfLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  surveyCommon: Model;

  evaluationId: string | null;

  currentEvaluation: BehaviorSubject<Evaluation | null> =
    new BehaviorSubject<Evaluation | null>(null);

  evaluationSummary: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  currentOrganization: BehaviorSubject<Organization | null> =
    new BehaviorSubject<Organization | null>(null);

  plotlyConfiguration = {
    config: {
      displayModeBar: false,
    },
  };
  tokenTypeNames;
  facilityNames;

  isPending: boolean = false;

  constructor(
    private resultService: ResultService,
    private evaluationService: EvaluationService,
    private organizationService: OrganizationService,
    public resultTestFilterService: ResultTestFiltersService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private pdfService: PdfService,
  ) {}

  ngOnInit() {
    this.resultTestFilterService.getPendingState().subscribe((pending) => {
      this.isPending = pending;
    });

    this.getEvaluationId();
    if (this.evaluationId) {
      this.evaluationService
        .getEvaluationById(this.evaluationId)
        .subscribe((evaluation) => {
          const currentLanguage = localStorage.getItem("lang") || "de";
          const surveyCategories = evaluation?.customSurvey?.pages?.map(
            (page) => ({
              title: page?.title?.[currentLanguage],
              id: page?.id,
              questions: page?.questions?.map((question) => ({
                id: question?.id,
                //@TODO strange wish of customer
                title: question?.title,
                indicator: question?.indicator,
              })),
              graphDataAll: [],
            }),
          );

          this.currentLanguage = currentLanguage;
          this.categories = surveyCategories;

          this.currentEvaluation.next(evaluation);
          this.getResultAndRender();
          this.countTokensByFilters();

          //todo need use only one subscription for filters

          this.resultTestFilterService.filters$
            .pipe(debounceTime(3000))
            .subscribe((filters) => {
              this.getGeneralResults(
                String(evaluation?.id),
                evaluation?.facilities,
                filters,
              );
            });

          this.tokenTypeNames = ENTITIES;
          const facilitiesForPdf = (evaluation?.facilities.map(
            (item) => item.facility_name,
          ) || []) as unknown as Array<string>;
          this.facilityNames = facilitiesForPdf as unknown as Array<string>;
          this.filterDataForPdf = {
            facilityNames: facilitiesForPdf,
            // tokenTypeNames: ENTITIES,
            tokenTypeNames: ENTITIES?.map(
              (entity) =>
                this.currentEvaluation?.value?.tokenDictionary?.[entity] ||
                entity,
            ),
          };

          if (this.currentEvaluation?.value?.id === 135) {
            this.filterDataForPdf.tokenTypeNames = ["MANAGEMENT"]?.map(
              (entity) =>
                this.currentEvaluation?.value?.tokenDictionary?.[entity] ||
                entity,
            );
          }
        })
        .add(() => {
          // if (this.currentEvaluation.value?.organization_id) {
          //@TODO hardcoded, need to be fixed
          this.organizationService
            .getOrganizationById(
              this.currentEvaluation.value?.organization_id || "1",
            )
            .subscribe((organization) => {
              this.currentOrganization.next(organization);
            });
          // }
        });
    }

    this.translate.get("category").subscribe((translation: string) => {
      this.translatedText = translation;
    });
  }

  ngOnDestroy() {
    this.evaluationId = null;
    this.graphDataAll = [];
  }

  private togglePdfLoading(value: boolean) {
    this.isPdfLoading.next(value);
  }

  getEvaluationId() {
    this.evaluationId = this.route.snapshot.paramMap.get("id");
  }

  getDataFromQuestionsDiagram(question: any) {
    const result = [];
    for (const key in question) {
      //@ts-ignore
      if (key !== "layout") result.push(question[key]);
    }
    return result;
  }

  getGeneralResults(
    evaluationId: string,
    facilities: Array<any>,
    currentFilters?: any,
  ) {
    this.resultTestFilterService.setPendingState(true);
    this.resultService
      .getEvaluationResultsGeneral(evaluationId, facilities)
      .subscribe((res) => {
        const allKeys = new Set();
        Object.keys(res).forEach((result) => {
          Object.keys(res[result]).forEach((key) => {
            allKeys.add(key);
          });
        });

        this.countTokensByFilters();
        let finalDataSource: Array<any> = [];

        const filteredNamesByIds = currentFilters?.facilityIds?.map(
          (id: string | number) =>
            facilities?.find((facility) => facility?.facility_id === id)
              ?.facility_name,
        );

        this.categories?.forEach((category) => {
          const categoryRow: any = {
            Frageindikator: `${this.translatedText}: ${category?.title}`,
          };
          categoryRow["Gesamter Mittelwert"] = "";
          categoryRow["Gesamter Mittelwert der Auswahl"] = "";
          Object.entries(res).forEach(([facility]: any) => {
            categoryRow[facility] = "";
          });
          finalDataSource.push(categoryRow);

          category?.questions
            ?.map((question) => question?.indicator)
            ?.forEach((key) => {
              const row: any = { Frageindikator: key };
              let intermidiateSum = 0;
              let totalFacilities = Object.entries(res)?.length;
              let totalFacilitiesWithoutNotAnswered = totalFacilities;

              Object.entries(res).forEach(([facility, result]: any, index) => {
                const rowValue = result[key as any] || "Nicht beantwortet";
                row[facility] = rowValue;
                if (rowValue !== "Nicht beantwortet") {
                  intermidiateSum = Number(intermidiateSum) + Number(rowValue);
                } else {
                  totalFacilitiesWithoutNotAnswered -= 1;
                }
                row["Gesamter Mittelwert"] = intermidiateSum;

                if (index === totalFacilities - 1) {
                  row["Gesamter Mittelwert"] = (
                    intermidiateSum / totalFacilitiesWithoutNotAnswered
                  ).toFixed(2);
                }
              });

              if (row["Gesamter Mittelwert"] !== "NaN") {
                finalDataSource.push(row);
              }
            });
        });

        // filter by facility
        if (currentFilters?.facilityIds?.length) {
          res = Object.keys(res)
            .filter((key) => filteredNamesByIds.includes(key))
            .reduce((obj, key) => {
              obj[key] = res[key];
              return obj;
            }, {});
        }
        // fill total average
        let changedDataSource = finalDataSource;

        if (currentFilters?.facilityIds?.length) {
          changedDataSource.map((row, ind) => {
            if (ind === 0) return row;
            let intermidiateSumOfSelect = 0;
            let totalFacilitiesForSelect = filteredNamesByIds?.length;
            filteredNamesByIds?.forEach((filteredName: string) => {
              if (row[filteredName]) {
                intermidiateSumOfSelect =
                  intermidiateSumOfSelect + Number(row[filteredName]);
              }
            });

            let totalAverageSelected: any = (
              intermidiateSumOfSelect / totalFacilitiesForSelect
            ).toFixed(2);

            if (isNaN(totalAverageSelected)) {
              totalAverageSelected = "";
            }

            row["Gesamter Mittelwert der Auswahl"] = totalAverageSelected;
          });
        }

        this.dataSource = changedDataSource || [];

        this.displayedColumns = [
          "Frageindikator",
          ...Object.keys(res),
          ...(!currentFilters?.facilityIds?.length
            ? []
            : ["Gesamter Mittelwert der Auswahl"]),
          "Gesamter Mittelwert",
        ];
      });
    this.resultTestFilterService.setPendingState(false);
  }

  filterByTextObj(obj: Record<string, string>) {
    let result = {};
    for (let key in obj) {
      if (isNaN(Number(obj[key]))) {
        result[key] = obj[key];
      }
    }
    return result;
  }

  getResultAndRender() {
    this.resultTestFilterService.filters$
      .pipe(debounceTime(3000))
      .subscribe((filters) => {
        if (this.isPending) {
          return;
        }
        this.resultTestFilterService.setPendingState(true);
        this?.categories?.forEach((category) => {
          if (category?.graphDataAll) category.graphDataAll = [];
        });
        this.graphDataAll = [];
        this.resultService
          .getEvaluationResultsNew(Number(this.evaluationId), {
            entities: filters.entities,
            facilityIds: filters.facilityIds,
          })
          .subscribe(
            (res) => {
              this.resultTestFilterService.setPendingState(false);
              const oldSurvey = this.evaluationService.mapOldSurvey(
                this.currentEvaluation?.value?.customSurvey,
              );
              this.surveyCommon = new Model(oldSurvey);

              let resultsWithOpenAnswer: Array<any> = [];

              for (const key in res) {
                res[key]?.forEach((answer) => {
                  const openAnswerCandidate = this.filterByTextObj(answer);
                  if (Object.keys(openAnswerCandidate)?.length) {
                    resultsWithOpenAnswer.push(openAnswerCandidate);
                  }
                });
              }

              this.surveyCommon.getAllQuestions()?.forEach((item) => {
                //@TODO understand what to do with different types of questions when they are in place
                const type = item?.getType() === "radiogroup" ? "bar" : "table";
                const choices = item?.["choices"]?.map((el) => el?.text) || [];
                if (type !== "table") {
                  this.graphDataAll.push({
                    EMPLOYEE: {
                      x: choices,
                      type,
                    },
                    CUSTOMER: {
                      x: choices,
                      type,
                    },
                    MANAGEMENT: {
                      x: choices,
                      type,
                    },
                    TEAM: {
                      x: choices,
                      type,
                    },
                    layout: {
                      id: Number(item?.name),
                      title: {
                        text: item?.["title"],
                        font: {
                          size: 16,
                          weight: "bold",
                        },
                      },
                      xaxis: { title: "" },
                      yaxis: { title: "" },
                      plot_bgcolor: "#f7f7f7",
                      paper_bgcolor: "#f7f7f7",
                      margin: {
                        l: 20,
                        r: 20,
                        t: 140,
                        b: 80,
                      },
                    },
                  });
                } else {
                  const candidates = resultsWithOpenAnswer
                    ?.filter((el) => el?.[item?.name])
                    ?.map((el) => ({ [item?.name]: el?.[item?.name] }));
                  this.graphDataAll.push({
                    type,
                    dataSource: candidates,
                    displayedColumns: [item?.name],
                    layout: {
                      // barnorm: 'percent',
                      id: Number(item?.name),
                      title: {
                        text: item?.["title"],
                        font: {
                          size: 16,
                          weight: "bold",
                        },
                      },
                      xaxis: { title: "" },
                      yaxis: { title: "" },
                      plot_bgcolor: "#f7f7f7",
                      paper_bgcolor: "#f7f7f7",
                      margin: {
                        l: 20,
                        r: 20,
                        t: 140,
                        b: 80,
                      },
                    },
                  });
                }
              });

              const surveyWithOpenAnswersOnly =
                this.evaluationService.getQuestionsWithOpenAnswers(
                  this.currentEvaluation?.value?.customSurvey,
                );

              for (const key in res) {
                this.surveyCommon.getAllQuestions()?.forEach((item, index) => {
                  const intermediateResult = {};
                  res[key]?.forEach((el) => {
                    if (intermediateResult[el?.[item?.name]]) {
                      intermediateResult[el?.[item?.name]] += 1;
                    } else {
                      intermediateResult[el?.[item?.name]] = 1;
                    }
                  });

                  const answers = item?.["choices"]?.map((el) => {
                    if (
                      Object.keys(intermediateResult)?.some(
                        (item) => item === el?.value,
                      )
                    ) {
                      return intermediateResult[el?.value];
                    } else return 0;
                  });

                  const answersTotal = answers?.reduce?.(
                    (el, acc) => el + acc,
                    0,
                  );

                  const answersPercent = answers?.map(
                    (el) => `${((el / answersTotal) * 100).toFixed(2)}%`,
                  );

                  this.graphDataAll[index] = {
                    ...this.graphDataAll[index],
                    [key]: {
                      ...this.graphDataAll[index]?.[key],
                      y: answers,
                      text: answersPercent,
                      name:
                        this?.currentEvaluation?.value?.tokenDictionary?.[
                          key
                        ] || key,
                    },
                  };
                });
              }

              this.graphDataAll?.forEach((graphDataEl) => {
                const graphDataElToShow = { ...graphDataEl };

                if (graphDataEl?.type !== "table") {
                  Object.keys(graphDataElToShow)?.forEach((graphDataElKey) => {
                    if (
                      !graphDataElToShow?.[graphDataElKey]?.["y"] &&
                      graphDataElKey !== "layout"
                    ) {
                      delete graphDataElToShow?.[graphDataElKey];
                    }
                  });
                }
                let currentCategory;

                this.categories?.forEach((category) => {
                  if (
                    category?.questions?.some(
                      (question) => question?.id == graphDataEl?.layout?.id,
                    )
                  ) {
                    currentCategory = category;
                  }
                });

                if (currentCategory?.graphDataAll) {
                  currentCategory.graphDataAll.push(graphDataElToShow);
                }
              });

              //@TODO this logic is not actual anymore
              if (resultsWithOpenAnswer?.length) {
                this.dataSourceOpenAnswers = resultsWithOpenAnswer;
                this.columnsOpenAnswers =
                  surveyWithOpenAnswersOnly?.pages?.reduce((acc, page) => {
                    acc = [...acc, ...page?.elements];
                    return acc;
                  }, []);
              }
            },
            () => {
              this.resultTestFilterService.setPendingState(false);
            },
          );
      });
  }

  countTokensByFilters() {
    this.resultTestFilterService.filters$
      .pipe(debounceTime(3000))
      .subscribe((filters) => {
        this.resultTestFilterService.setPendingState(true);
        this.evaluationService
          .getEvaluationSummaryById(
            this.evaluationId as string,
            filters.facilityIds,
            filters.entities,
          )
          .subscribe(
            (summary) => {
              this.evaluationSummary.next(summary);
              this.resultTestFilterService.setPendingState(false);
            },
            () => {
              this.resultTestFilterService.setPendingState(false);
            },
          );
      });
  }

  checkCategoryByTitle(text: string) {
    return text.includes(this.translatedText);
  }

  getFiltersData(event: any) {
    const { entities, facilityIds } = event;
    const defaultFacilities = this?.currentEvaluation?.value?.facilities;

    if (facilityIds.length) {
      this.facilityNames = defaultFacilities
        ?.filter((item) => facilityIds.includes(item.facility_id))
        .map((item) => item.facility_name);
    } else {
      this.facilityNames = defaultFacilities?.map((item) => item.facility_name);
    }

    const dictionaryTypes =
      this?.currentEvaluation?.value?.tokenDictionary || {};

    if (entities.length) {
      let filteredTypeNames = ENTITIES.filter((typeName) =>
        entities.includes(typeName),
      );
      if (Object.entries(dictionaryTypes).length) {
        filteredTypeNames = filteredTypeNames.map(
          (typeName) => dictionaryTypes[typeName] ?? typeName,
        );
      }
      this.tokenTypeNames = filteredTypeNames;
    } else {
      if (Object.entries(dictionaryTypes).length) {
        this.tokenTypeNames = ENTITIES.map(
          (typeName) => dictionaryTypes[typeName] ?? typeName,
        );
      } else {
        this.tokenTypeNames = ENTITIES;
      }
    }

    this.filterDataForPdf = {
      facilityNames: this.facilityNames,
      tokenTypeNames: this.tokenTypeNames,
    };
  }

  handleExport() {
    this.pdfService.generateResultPDF({
      filtersData: this.filterDataForPdf,
      togglePdfLoading: this.togglePdfLoading.bind(this),
      currentEvaluation: this.currentEvaluation.value,
      graphDataAll: this.graphDataAll,
      evaluationSummary: this.evaluationSummary.value,
      organizationTitle: this.currentOrganization?.value?.name,
      categories: this.categories,
      dataSourceGeneral: this.dataSource,
      columnsGeneral: this.displayedColumns,
      dataSourceOpenAnswers: this.dataSourceOpenAnswers,
      columnsOpenAnswers: this.columnsOpenAnswers,
    });
  }

  getCellColor(
    value: number,
    average: number,
    isNeedSkipElement: boolean,
  ): string {
    if (isNeedSkipElement || value.toString() === "Nicht beantwortet") {
      return "black";
    }

    if (value < average) {
      return "red";
    } else if (value > average) {
      return "green";
    } else {
      return "blue";
    }
  }
}
