<div class="container" id="surveyResults">
    <img
        *ngIf="this.currentEvaluation.value?.logo"
        [src]="this.currentEvaluation.value?.logo"
        alt="evaluation_logo"
        class="evaluationLogo"
    />
    <div *ngIf="!this.currentEvaluation.value?.logo" class="evaluationLogo"></div>
    <div class="generalInfo">
        <h3 class="evaluation_heading">{{ 'evaluationResultsFor' | translate }}</h3>
        <h3 class=" evaluation_heading">&nbsp;{{this.currentEvaluation.value?.name }}</h3>
    </div>
    <div *ngIf="this.currentOrganization.value?.name" class="generalInfo">
        <h3 class="evaluation_heading">{{ 'nameOfOrganization' | translate }}: </h3>
        <h3 class=" evaluation_heading"> {{ this.currentOrganization.value?.name }}</h3>
    </div>
    <div class="row">
        <div class="column">
            <div class="infoBlock">
                <strong>{{ 'startDateSummary' | translate }}</strong>
                <span>{{this.currentEvaluation.value?.startdate}}</span>
            </div>
            <div class="infoBlock">
                <strong>{{ 'endDateSummary' | translate }}</strong>
                <span>{{this.currentEvaluation.value?.enddate}}</span>
            </div>
            <div class="infoBlock">
                <strong>{{ 'deliveredTokens' | translate }}</strong>
                <span>{{this.evaluationSummary.value?.deliveredTokens}}</span>
            </div>
            <div class="infoBlock">
                <strong>{{ 'usedTokens' | translate }}</strong>
                <span>{{this.evaluationSummary.value?.usedTokens}}</span>
            </div>
        </div>
        <div class="column">
            <div class="infoBlock">
                <strong>{{ 'finalResult' | translate }}</strong>
                <span>{{this.evaluationSummary.value?.finalResults}}</span>
            </div>
            <div class="infoBlock">
                <strong>{{ 'intermediateResults' | translate }}</strong>
                <span>{{this.evaluationSummary.value?.intermediateResults}}</span>
            </div>
            <div class="infoBlock">
                <strong>{{ 'unusedTokens' | translate }}</strong>
                <span>{{this.evaluationSummary.value?.unusedTokens}}</span>
            </div>
        </div>
        <div class="column">
            <div class="infoBlock" *ngIf="this.tokensCounted?.['generatedCustomerTokens']">
                <strong>{{'tokens' | translate}} {{ this?.currentEvaluation?.value?.tokenDictionary?.["CUSTOMER"] || "TYPE_1" }}</strong>
                <span>{{this.tokensCounted['generatedCustomerTokens']}}</span>
            </div>
            <div class="infoBlock" *ngIf="this.tokensCounted?.['generatedEmployeeTokens']">
                <strong>{{'tokens' | translate}} {{ this?.currentEvaluation?.value?.tokenDictionary?.["EMPLOYEE"] || "TYPE_2" }}</strong>
                <span>{{this.tokensCounted['generatedEmployeeTokens']}}</span>
            </div>
            <div class="infoBlock" *ngIf="this.tokensCounted?.['generatedTeamTokens']">
                <strong>{{'tokens' | translate}} {{ this?.currentEvaluation?.value?.tokenDictionary?.["TEAM"] || "TYPE_3" }}</strong>
                <span>{{this.tokensCounted['generatedTeamTokens']}}</span>
            </div>
            <div class="infoBlock" *ngIf="this.tokensCounted?.['generatedManagementTokens']">
                <strong>{{'tokens' | translate}} {{ this?.currentEvaluation?.value?.tokenDictionary?.["MANAGEMENT"] || "TYPE_4" }}</strong>
                <span>{{this.tokensCounted['generatedManagementTokens']}}</span>
            </div>

        </div>
    </div>
</div>