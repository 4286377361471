import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogModule,
} from "@angular/material/dialog";
import { SurveyPreviewDialogContentComponent } from "../survey-preview-dialog-content/survey-preview-dialog-content.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "survey-preview-dialog",
  templateUrl: "survey-preview-dialog.component.html",
  styleUrls: ["survey-preview-dialog.component.scss"],
  standalone: true,
  imports: [MatButtonModule, MatDialogModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogContentExample {
  @Input() evaluationFormData: any; //todo
  @Input() designerFormData: any; //todo
  @Input() formTokens: Array<{ code: string; label: string }>;

  constructor(public matDialog: MatDialog) {}

  openDialog(event) {
    event.stopPropagation();
    event.preventDefault();

    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;

    dialogConfig.data = {
      evaluationFormData: this.evaluationFormData,
      designerFormData: this.designerFormData,
      formTokens: this.formTokens,
    };
    dialogConfig.width = "1200px";
    const dialogRef = this.matDialog.open(
      SurveyPreviewDialogContentComponent,
      dialogConfig,
    );

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
