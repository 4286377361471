<section class="footer">
    <div class="container-2">
        <div class="footer-bottom">
            <div class="footer-legal-block">
                <a
                        *ngIf="this.faqService.faq.value"
                        (click)="this.onFaqClick()"
                        [routerLink]="['/', NavigationService.FAQPAGE]"
                        target="_blank"
                        class="link-text"
                >
                    FAQ
                </a>
                <a [routerLink]="['/', NavigationService.TERMSANDCONDITIONS]"
                   class="link-text"
                   target="_blank">{{'termsOfUse' | translate}}</a>
                <a [routerLink]="['/', NavigationService.PRIVACYPOLICY]"
                   class="link-text"
                   target="_blank">{{'dataProtection' | translate}}</a>
                <a
                        [routerLink]="['/', NavigationService.ADMINPAGE]"
                        class="link-text"
                        *ngIf="this.authenticationService.isAdmin.value"
                >
                    {{'administration' | translate}}
                </a>
            </div>
        </div>
    </div>
</section>
