import { Component, Input, OnInit } from "@angular/core";
import { Evaluation } from "../../model/Evaluation";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../../services/authentication.service";
import { ContextService } from "../../services/context.service";
import { EvaluationService } from "../../services/evaluation.service";
import { SnackBarService } from "../../services/snack-bar.service";
import { TranslateService } from "@ngx-translate/core";

const ADMIN_ROUTE_URL = "admin";

@Component({
  selector: "app-login-token",
  templateUrl: "./login-token.component.html",
  styleUrls: ["login-token.component.css"],
})
export class LoginTokenComponent implements OnInit {
  @Input() evaluations: Evaluation[];
  @Input() chosenEvaluationFromList: Evaluation;
  @Input() chosenEvaluation: Evaluation;

  _userAccessCode: string;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    public context: ContextService,
    private evaluationService: EvaluationService,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.getChosenEvaluation();

    if (this.route.snapshot.routeConfig?.path === ADMIN_ROUTE_URL) {
      this.switchToAdminView();
    }
  }

  switchToAdminView() {
    this.authenticationService.loginWithEmail = true;
  }

  findEvaluation() {
    if (this.chosenEvaluationFromList) {
      this.chosenEvaluation = this.chosenEvaluationFromList;
    } else {
      this.getChosenEvaluation();
    }
    return this.chosenEvaluation;
  }

  getChosenEvaluation() {
    if (this.context.requestedEvaluationID !== String(null)) {
      this.evaluationService
        .getEvaluationById(this.context.requestedEvaluationID)
        .subscribe((result) => {
          this.chosenEvaluation = result;
        });
    }
  }

  loginViaToken() {
    this.authenticationService.loginViaToken(
      this._userAccessCode,
      this.findEvaluation(),
    );
  }
}
