<div class="container">
    <div class="checkboxesContainer" *ngIf="this?.currentEvaluation?.value?.id">
        <h5 class="label">Entities</h5>
        <div *ngFor="let entity of entities" class="checkBoxLayout">
            <mat-checkbox [id]="'entity' + entity" (change)="updateEntityFilters(entity, $event.checked)"/>
            <label class="label" [for]="'entity' + entity">{{tokenDictionary?.[entity] || entity}}</label>
        </div>
    </div>
    <div *ngIf="facilities?.length" class="checkboxesContainer">
        <h5 class="label">Facilities</h5>
        <div *ngFor="let facility of facilities" class="checkBoxLayout">
            <mat-checkbox [id]="'facility' + facility?.facility_id" (change)="updateFacilityFilters(facility?.facility_id, $event.checked)"/>
            <label class="label" [for]="'facility' + facility?.facility_id">{{facility?.facility_name}}</label>
        </div>
    </div>
</div>