<app-sidebar>
    <div class="container">
        <div class="searchbar">
            <div class="search-form">
                <mat-form-field class="search-form" appearance="outline" >
                    <mat-icon matIconPrefix>search</mat-icon>
                    <input matInput  placeholder="{{'searchByEvaluationName' | translate}}" name="searchTerm" [(ngModel)]="searchTerm" (input)="search(searchTerm)">
                </mat-form-field>
            </div>
            <mat-form-field appearance="outline" >
                <mat-select class="filters_select" [(ngModel)]="selectedState" (ngModelChange)="handleSelectState(selectedState)" >
                    <mat-option value="All">All</mat-option>
                    <mat-option value="Published">Published</mat-option>
                    <mat-option value="Created">Created</mat-option>
                    <mat-option value="Deactivated">Deactivated</mat-option>
                    <mat-option value="Deleted">Deleted</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="card-container card-grid ">
            <ng-container *ngFor="let evaluation of filteredEvaluations | searchFilter: searchTerm; index as i">
                <mat-card class="card">
                    <div class="align_menu">
                        <div class="card-header ">
                            <span class="truncate_name">{{ evaluation.name }}</span>
                        </div>
                        <div class="dropdown">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="onShowResults(evaluation.id)"
                                        [disabled]="disableSeeResultButton(evaluation)">
                                    <span>{{'seeResults' | translate}}</span>
                                </button>
                                <button mat-menu-item (click)="onEdit(evaluation)"
                                        [disabled]="disableEditButton(evaluation)">
                                    <span>{{'editEvaluation' | translate}}</span>
                                </button>
                                <button mat-menu-item (click)="openModal('warningDeactivateEvaluationText',
                                'hintDeactivationEvaluation',
                                'yesDeactivate',
                                evaluation.id,
                                'DEACTIVATED')"
                                        [disabled]="disableDeactivationButton(evaluation)">
                                    <span>{{'deactivateEvaluation' | translate}}</span>
                                </button>
                                <button mat-menu-item (click)="openModal('warningReactivateEvaluation',
                                'hintReactivateEvaluation',
                                'yesReactivate',
                                evaluation.id,
                                'PUBLISHED')"
                                        [disabled]="disableReactivationButton(evaluation)">
                                    <span>{{'reactivateEvaluation' | translate}}</span>
                                </button>
                                <button mat-menu-item (click)="openModal('warningDeleteEvaluation',
                                'hintDeleteEvaluation',
                                'yesDelete',
                                evaluation.id,
                                'DELETED')"
                                        [disabled]="disableDeleteButton(evaluation)">
                                    <span>{{'deleteEvaluation' | translate}}</span>
                                </button>
                                <button mat-menu-item
                                        [disabled]="disableDeleteButton(evaluation)"
                                        (click)="handleTokensPageNavigate(evaluation?.id)"
                                >
                                    <span>{{'tokens' | translate}}</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>

                    <div class="card-body">
                        <span>{{'from' | translate}}: {{evaluation.startdate}} {{'to' | translate}}: {{evaluation.enddate}}</span><br>
                        <span>{{'lastUpdate' | translate}}: {{evaluation.updatedate ?? evaluation.creationdate}}</span><br>
                        <div class="align_menu_bottom">
                            <span>{{'state' | translate}}: {{evaluation.state}}</span>
                            <div class="publishedCircle" *ngIf="this.evaluation.state === 'PUBLISHED'"></div>
                            <mat-icon class="state_icon blue_icon" *ngIf="this.evaluation.state === 'CREATED'">
                                description
                            </mat-icon>
                            <mat-icon class="state_icon grey_icon" *ngIf="this.evaluation.state === 'DELETED'">delete
                            </mat-icon>
                            <div class="deactivatedCircle" *ngIf="this.evaluation.state === 'DEACTIVATED'"></div>
                        </div>
                    </div>
                </mat-card>
            </ng-container>
        </div>
    </div>

</app-sidebar>


