import { Component, Input } from "@angular/core";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { PdfService } from "../services/pdfService";

const PLUG_FOR_PDF = {
  logo: "",
  tokens: [{ code: "EMPLOYEE", type: "EMPLOYEE" }],
  evaluationId: "test-id",
  evaluationDescription: "",
  facilityName: "",
  tokenDictionary: { EMPLOYEE: "custom-token" },
  descriptionPdf: "",
  devicesDescriptionPdf: "",
  entryDescriptionPdf: "",
  editingPdf: "",
  durationMinutesPdf: "",
  resultsPdf: "",
  afterQRCodeDescriptionPdf: "",
  fullNamePdf: "",
  personPositionPdf: "",
  farewellTextPdf: "",
  contactPersonPdf: "",
  durationDatesPdf: new Date(),
};

@Component({
  selector: "custom-pdf-fields",
  templateUrl: "./custom-pdf-fields.component.html",
  styleUrls: ["./custom-pdf-fields.component.css"],
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule,
    MatButtonModule,
  ],
})
export class CustomPdfFieldsComponent {
  @Input() formGroup: FormGroup;
  @Input() logoFromRequest?: string | null;

  constructor(public pdfService: PdfService) {}

  generatePdfForPreview(event) {
    event.preventDefault();
    event.stopPropagation();

    this.pdfService.generateTokensPDF({
      ...PLUG_FOR_PDF,
      ...this.formGroup.value,
      logo: null,
    });
  }
}

//todo add text-area to farewell text
