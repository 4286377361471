import { Component, Input, OnInit } from "@angular/core";
import { Organization } from "../model/Organization";
import { OrganizationService } from "../services/organization.service";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, startWith } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-organization-list",
  templateUrl: "./organization-list.component.html",
  styleUrls: ["./organization-list.component.css"],
})
export class OrganizationListComponent implements OnInit {
  @Input() formControl: FormControl;
  @Input() formGroup: FormGroup;

  organizations: Organization[];
  filteredOrganizations: Observable<Organization[]>;

  constructor(private organizationService: OrganizationService) {}

  ngOnInit(): void {
    this.organizationService.getOrganizations().subscribe((response) => {
      this.organizations = response;

      if (this.formControl?.value?.id && !this.formControl?.value?.name) {
        const defaultValue = this.organizations.find(
          (org) => org.id === this.formControl?.value?.id,
        );
        this.formControl.setValue(defaultValue);
      }

      this.filteredOrganizations = this.formControl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(typeof value === "string" ? value : "")),
      );
    });
  }

  private _filter(value: string): Organization[] {
    const filterValue = value.toLowerCase();
    return this.organizations?.filter((option) =>
      option.name.toLowerCase().includes(filterValue),
    );
  }

  displayFn(organization: Organization): string {
    return organization && organization.name ? organization.name : "";
  }
}
