import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TokenLoginComponent } from "./login-page/token-login.component";
import { AdminPageComponent } from "./admin-page/admin-page.component";
import { ResultComponent } from "./survey/resultDashboard/result.component";
import { SurveyCreatorComponent } from "./survey/survey-creator/survey-creator.component";
import { AdminGuard } from "./services/permissions.service";
import { FaqComponent } from "./faq/faq.component";
import { ResultTestComponent } from "./survey/resultDashboard/result-test/result-test.component";
import { EvaluationUpdateComponent } from "./survey/survey-creator/evaluation-update/evaluation-update.component";
import { FinishSurveyComponent } from "./finish-survey/finish-survey.component";
import { OrganizationsComponent } from "./organizations/organizations.component";
import { OrganizationDetailsComponent } from "./organizations/organization-details/organization-details.component";
import { OrganizationCreatorComponent } from "./organizations/organization-creator/organization-creator.component";
import { FacilityDetailsComponent } from "./organizations/facility-details/facility-details.component";
import { FacilityCreatorComponent } from "./organizations/facility-creator/facility-creator.component";
import { TokensComponent } from "./tokens/tokens.component";
import { SurveyRepresentationComponent } from "./survey-representation/survey-representation.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { DesignerComponent } from "./designer/designer.component";

const routes: Routes = [
  { path: "survey-page/:id", component: SurveyRepresentationComponent },
  { path: "", component: TokenLoginComponent },
  { path: "admin", component: TokenLoginComponent },
  { path: "login-page", component: TokenLoginComponent },
  { path: "faq", component: FaqComponent },
  {
    path: "finish-survey-page/:evaluationId",
    component: FinishSurveyComponent,
  },
  {
    path: "admin-page",
    component: AdminPageComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "result-page",
    component: ResultComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "result/:id",
    component: ResultTestComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "creator-page",
    component: SurveyCreatorComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "creator-page/:id",
    component: SurveyCreatorComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "update/:id",
    component: EvaluationUpdateComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "organizations",
    component: OrganizationsComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "tokens/:evaluationId",
    component: TokensComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "organization/:organizationId",
    component: OrganizationDetailsComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "organization/:organizationId/facility/:facilityId",
    component: FacilityDetailsComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "organization-creator",
    component: OrganizationCreatorComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "organization-creator/:organizationId",
    component: OrganizationCreatorComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "organization/:organizationId/facility-creator",
    component: FacilityCreatorComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "organization/:organizationId/facility/:facilityId/edit",
    component: FacilityCreatorComponent,
    canActivate: [AdminGuard],
  },
  { path: "designer", component: DesignerComponent, canActivate: [AdminGuard] },
  { path: "survey", component: SurveyRepresentationComponent },
  { path: "termsandconditions", component: TermsAndConditionsComponent },
  { path: "privacypolicy", component: PrivacyPolicyComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
