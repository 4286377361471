<div class="container">
    <div class="sub-container">
        <form (ngSubmit)="this.onSubmit()"
              [formGroup]="myForm" id="loginForm" name="wf-form-Email-Form" data-name="Email Form" method="get">
            <div>
                <label>{{ 'chooseTheSurveyTitle' | translate}}</label>
                <mat-form-field appearance="outline" class="full-width padding-r-0">
                    <mat-label>{{ 'title' | translate }}</mat-label>
                    <input formControlName="title" matInput
                           placeholder="{{ 'pleaseWriteTheNameOfEvaluation' | translate }}">
                </mat-form-field>
                <div class="error"
                     *ngIf="myForm.get('title')?.invalid && (myForm.get('name')?.dirty || myForm.get('name')?.touched)">
                    {{ 'fieldIsRequired' | translate}}
                </div>
            </div>
            <ng-container>
                <div formArrayName="pages">
                    <div class="full-width padding-r-0">
                        <label>{{ 'pages' | translate }}</label>
                        <mat-button-toggle
                                type="submit"
                                [class.disabled]="pages.invalid"
                                [disabled]="title?.invalid"
                                (click)="addPage()"
                        >
                            <mat-icon>add</mat-icon>
                        </mat-button-toggle>
                    </div>
                    <div *ngFor="let page of pages.controls; index as pageIndex" [formGroupName]="pageIndex"
                         class="added-pages">
                        <div class="accordion">
                            <mat-accordion class="example-headers-align" multi>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header class="extensionPanelHeader">
                                        <mat-panel-title class="formElementLabel">
                                            <span *ngIf="!!this.myForm.value.pages[pageIndex].title?.[currentLanguage]">
                                              {{this.myForm.value.pages[pageIndex].title?.[currentLanguage]}}
                                            </span>
                                            <span *ngIf="!this.myForm.value.pages[pageIndex].title?.[currentLanguage]">
                                              {{ 'page' | translate }} {{pageIndex + 1}}
                                            </span>

                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <label>{{'categoryTitle' | translate}}</label>
                                    <mat-tab-group formGroupName="title">
                                        <mat-tab *ngFor="let lang of languages" [label]="lang.label">
                                            <div class="titleInput">
                                                <mat-form-field appearance="outline" class="full-width padding-r-0">
                                                    <mat-label>{{ 'title' | translate }} {{lang.label}}</mat-label>
                                                    <input [formControlName]="lang.code" matInput
                                                           [placeholder]="lang.placeholder">
                                                </mat-form-field>
                                                <div class="error"
                                                     *ngIf="myForm.get('pages.' + pageIndex + '.title.' + lang.code)?.invalid && (myForm.get('pages.' + pageIndex + '.title.' + lang.code)?.dirty || myForm.get('pages.' + pageIndex + '.title.' + lang.code)?.touched)">
                                                    {{ 'fieldIsRequired' | translate }}
                                                </div>
                                            </div>
                                        </mat-tab>
                                    </mat-tab-group>

                                    <div class="full-width padding-r-0">
                                        <label>{{ 'questions' | translate }}</label>
                                        <mat-button-toggle
                                                [class.disabled]="getQuestions(pageIndex).invalid"
                                                           [disabled]="getQuestions(pageIndex).invalid || !checkTitleFromPageIsValid()"
                                                           (click)="addQuestion(pageIndex)">
                                            <mat-icon>add</mat-icon>
                                        </mat-button-toggle>
                                    </div>

                                    <div class="questions" formGroupName="questions">
                                        <div *ngFor="let question of getQuestions(pageIndex).controls; index as questionIndex"
                                             [formGroupName]="questionIndex" class="question">
                                            <label>{{ 'question' | translate}} {{questionIndex + 1}}</label>
                                            <div class="titleInput">
                                                <mat-tab-group formGroupName="title">
                                                    <mat-tab *ngFor="let tokenType of tokenTypes"
                                                             [label]="tokenType.label">
                                                        <mat-tab-group [formGroupName]='tokenType.code'>
                                                            <mat-tab *ngFor="let lang of languages"
                                                                     [label]="lang.label">
                                                                <div class="titleInput">
                                                                    <mat-form-field appearance="outline"
                                                                                    class="full-width padding-r-0">
                                                                        <mat-label>
                                                                            {{ 'title' | translate}} {{tokenType.label}} {{lang.label}}</mat-label>
                                                                        <textarea [formControlName]="lang.code"
                                                                                  matInput
                                                                                  [placeholder]="lang.placeholder"></textarea>
                                                                    </mat-form-field>
                                                                </div>
                                                            </mat-tab>
                                                        </mat-tab-group>
                                                    </mat-tab>
                                                </mat-tab-group>
                                            </div>

                                            <label>{{ 'questionTip' | translate }}</label>
                                            <mat-tab-group formGroupName="tips">
                                                <mat-tab *ngFor="let lang of languages" [label]="lang.label">
                                                    <div class="titleInput">
                                                        <mat-form-field appearance="outline" class="full-width padding-r-0">
                                                            <mat-label>{{ 'tip' | translate }} {{lang.label}}</mat-label>
                                                            <input [formControlName]="lang.code" matInput
                                                                   [placeholder]="lang.placeholder">
                                                        </mat-form-field>
                                                        <div class="error"
                                                             *ngIf="myForm.get('pages.' + pageIndex + 'questions.' + questionIndex + '.tips.de')?.invalid
                                                                 && (myForm.get('pages.' + pageIndex + 'questions.' + questionIndex + '.tips.de')?.dirty
                                                                 || myForm.get('pages.' + pageIndex + 'questions.' + questionIndex + '.tips.de')?.touched)"
                                                        >
                                                            {{ 'fieldIsRequired' | translate}}
                                                        </div>
                                                    </div>
                                                </mat-tab>
                                            </mat-tab-group>

                                            <div class="titleInput">
                                                <label>{{ 'indicator' | translate }}</label>
                                                <mat-form-field appearance="outline" class="full-width padding-r-0">
                                                    <mat-label>{{ 'indicator' | translate }}</mat-label>
                                                    <input formControlName="indicator" matInput
                                                           placeholder="Please describe indicator">
                                                </mat-form-field>
                                            </div>

                                            <div class="titleInput">
                                                <label>{{ 'questionType' | translate }}</label>
                                                <div>
                                                    <small>{{ 'questionTypesTip' | translate }}</small>
                                                </div>
                                                <mat-form-field>
                                                    <mat-select formControlName="type">
                                                        <mat-option *ngFor="let questionType of questionTypes"
                                                                    [value]="questionType['value']">{{questionType['viewValue']}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                            <div class="full-width padding-r-0">
                                                <label>{{ 'questionOptions' | translate }}</label>
                                                <mat-button-toggle
                                                        [class.disabled]="getQuestionOptions(pageIndex, questionIndex).invalid"
                                                        [disabled]="getQuestions(pageIndex).invalid"
                                                        (click)="addQuestionOption(pageIndex, questionIndex)">
                                                    <mat-icon>add</mat-icon>
                                                </mat-button-toggle>
                                            </div>


                                            <div class="options" formGroupName="options">
                                                <div *ngFor="let option of getQuestionOptions(pageIndex, questionIndex).controls; index as optionIndex"
                                                     [formGroupName]="optionIndex" class="option">

                                                    <div class="titleInput">
                                                        <label>{{ 'optionValue' | translate}}</label>
                                                        <mat-form-field appearance="outline" class="full-width padding-r-0">
                                                            <mat-label>{{ 'value' | translate}}</mat-label>
                                                            <input formControlName="value" matInput type="number"
                                                                   min="-1" max="999"
                                                                   placeholder="Please describe indicator">
                                                        </mat-form-field>
                                                    </div>

                                                    <mat-tab-group formGroupName="title">
                                                        <mat-tab *ngFor="let lang of languages"
                                                                 [label]="lang.label">
                                                            <div class="titleInput">
                                                                <mat-form-field appearance="outline"
                                                                                class="full-width padding-r-0">
                                                                    <mat-label>{{ 'title' | translate}} {{lang.label}}</mat-label>
                                                                    <input [formControlName]="lang.code" matInput
                                                                           [placeholder]="lang.placeholder">
                                                                </mat-form-field>
                                                                <div class="error"
                                                                     *ngIf="myForm.get('pages.' + pageIndex + 'questions.' + questionIndex + 'options' + optionIndex + 'title.de')?.invalid
                                                                         && (myForm.get('pages.' + pageIndex + 'questions.' + questionIndex + 'options' + optionIndex + 'title.de')?.dirty
                                                                         || myForm.get('pages.' + pageIndex + 'questions.' + questionIndex + 'options' + optionIndex + 'title.de')?.touched)">
                                                                    {{ 'fieldIsRequired' | translate}}
                                                                </div>
                                                            </div>
                                                        </mat-tab>
                                                    </mat-tab-group>

                                                    <div class="removeButtonBlock">
                                                        <mat-button-toggle
                                                                (click)="removeQuestionsOption(pageIndex, questionIndex, optionIndex)">
                                                            <mat-icon>close</mat-icon>
                                                        </mat-button-toggle>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="removeButtonBlock">
                                                <mat-button-toggle (click)="removeQuestion(pageIndex, questionIndex)">
                                                    <mat-icon>close</mat-icon>
                                                </mat-button-toggle>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="removeButtonBlock">
                                        <mat-button-toggle (click)="removePage(pageIndex)">
                                            <mat-icon>close</mat-icon>
                                        </mat-button-toggle>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </div>
            </ng-container>
            <survey-preview-dialog
               [evaluationFormData]="this.evaluationFormData"
               [designerFormData]="this.myForm.value"
               [formTokens]="this.tokenTypes"
            />
            <div class="full-width padding-r-0 submit-button">
                <label>*{{ 'surveyFormInfo' | translate}}</label>

                <mat-button-toggle
                        type='submit'
                        [disabled]="getDisabledForm()"
                        class="full-width padding-r-0"
                        (click)="onSubmit()"
                >
                    {{'publishEvaluation' | translate}}
                </mat-button-toggle>
            </div>
        </form>
    </div>
</div>

