<div class="container" *ngIf="this.language">
    <div  *ngIf="evaluationLogo" class="logo-container">
        <img class="logo" alt="logo" [src]="evaluationLogo"/>
    </div>
    <div class="title-container">
        <h1 class="title-text">{{this.evaluationTitle}}</h1>
    </div>
<!--    {{ resultsForm.value | json }}-->
    <div class="sub-container">
        <form [formGroup]="resultsForm">
            <div class="survey-container" id="survey-container">
                <mat-tab-group color="primary" [selectedIndex]="selectedIndex" (selectedTabChange)="onTabChange($event)">
                    <mat-tab *ngFor="let page of this.survey.pages">
                        <ng-template mat-tab-label>{{page.title[this.language]}}</ng-template>
                        <div>
                            <div *ngFor="let question of page.questions" class="question-container">
                                <mat-expansion-panel
                                    class="expansion-panel"
                                    (opened)="panelOpenState = true"
                                    (closed)="panelOpenState = false"
                                    [disabled]="!question.title[this?.tokenType || ''][this.language]"
                                >
                                    <mat-expansion-panel-header class="section-header">
                                        <mat-panel-title>
                                            {{question.title[this?.tokenType || ''][this.language]}}
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div class="tip">
                                        {{question.tip[this.language]}}
                                    </div>
                                </mat-expansion-panel>
                                <div class="options-container">
                                    <div *ngIf="question.type === 'radio' || question.type === 'boolean' ">
                                        <mat-radio-group color="primary" formControlName="{{question.id}}">
                                            <div *ngFor="let option of question.options">
                                                <mat-radio-button [value]="option.value">
                                                    <span class="radio-button-text">{{option.title[this.language]}}</span>
                                                </mat-radio-button>
                                            </div>
                                        </mat-radio-group>
                                    </div>

                                    <div *ngIf="question.type === 'text' ">
                                        <mat-form-field appearance="outline" class="textAreaFormField text-answer-wrapper">
                                            <textarea
                                                    formControlName="{{question.id}}"
                                                    matInput
                                                    width="100%"
                                                    maxlength="255"
                                            ></textarea>
                                            <div>
                                                {{'maxCharacters255' | translate}}
                                            </div>
                                        </mat-form-field>
                                    </div>
                                    <!--                            <div *ngIf="question.type === 'boolean'">-->
                                    <!--                                <div class="button-toggle-container">-->
                                    <!--                                    <mat-radio-group color="primary" formControlName="{{question.indicator}}">-->
                                    <!--                                        <div *ngFor="let option of question.options">-->
                                    <!--                                            <mat-radio-button [value]="option.value">-->
                                    <!--                                                {{option.title[this.language]}}-->
                                    <!--                                            </mat-radio-button>-->
                                    <!--                                        </div>-->
                                    <!--                                    </mat-radio-group>-->
                                    <!--                                    &lt;!&ndash;                                <mat-button-toggle-group&ndash;&gt;-->
                                    <!--                                    &lt;!&ndash;                                        class="custom-button-toggle"&ndash;&gt;-->
                                    <!--                                    &lt;!&ndash;                                        formControlName="{{question.id}}">&ndash;&gt;-->
                                    <!--                                    &lt;!&ndash;                                    <div *ngFor="let option of question.options">&ndash;&gt;-->
                                    <!--                                    &lt;!&ndash;                                        <mat-button-toggle&ndash;&gt;-->
                                    <!--                                    &lt;!&ndash;                                                [value]="option.value">{{option.title[this.language]}}</mat-button-toggle>&ndash;&gt;-->
                                    <!--                                    &lt;!&ndash;                                    </div>&ndash;&gt;-->
                                    <!--                                    &lt;!&ndash;                                </mat-button-toggle-group>&ndash;&gt;-->
                                    <!--                                </div>-->
                                    <!--                            </div>-->
                                    <div class="slider-container" *ngIf="question.type === 'range' ">
                                        <span class="slider-limits-text">1</span>
                                        <mat-slider
                                                color="primary" [max]="(6)"
                                                [min]="(1)"
                                                [step]="(1)"
                                                [discrete]="true"
                                                [showTickMarks]="true">
                                            <input matSliderThumb formControlName="{{question.id}}">
                                        </mat-slider>
                                        <span class="slider-limits-text">6</span>
                                    </div>
<!--                                    <div *ngIf="question.type === 'multiselect'" formArrayName="{{ question.id }}">-->
<!--                                        <section>-->
<!--                                            <div *ngFor="let option of question.options; let i = index">-->
<!--                                                <mat-checkbox [value]="option.value" [formControlName]="i"-->
<!--                                                              (change)="onCheckboxChange($event.checked, question.id, option.value)">{{option.title[this.language]}}</mat-checkbox>-->
<!--                                            </div>-->
<!--                                        </section>-->
<!--                                    </div>-->
                                </div>

                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </form>
    </div>
    <div class="button-container">
        <div class="previous-button-container">
            <button class="button-previous" *ngIf="this.selectedIndex > 0" mat-raised-button color="basic"
                    (click)="goToPreviousPage()">{{'previous' | translate}}
            </button>
        </div>
        <div class="next-complete-button-container">
            <button class="button" *ngIf="this.selectedIndex < this.maxPagesNumber - 1" mat-raised-button color="primary"
                    (click)="goToNextPage()">{{'next' | translate}}
            </button>
            <button class="button" *ngIf="this.selectedIndex === this.maxPagesNumber - 1" mat-raised-button
                    color="primary"
                    (click)="completeSurvey()">{{'completeSurvey' | translate}}
            </button>
        </div>
    </div>
</div>