<div class="container additional-fields" [formGroup]="formGroup">
    <div class="formElementLabel">
        {{ 'configurePdf' | translate }}
    </div>

    <div class="additional-fields__wrapper" >
<!--        <div class="additional-fields__wrapper__item">-->
<!--            <label>{{ 'descriptionPdfLabel' | translate }}</label>-->
<!--            <mat-form-field class="mat-form-field" appearance="outline">-->
<!--                <input formControlName="descriptionPdf" matInput placeholder="{{'descriptionPdf' | translate}}">-->
<!--            </mat-form-field>-->
<!--        </div>-->

        <div class="additional-fields__wrapper__item">
            <label>{{ 'devicesDescriptionPdfLabel' | translate }}</label>
            <mat-form-field class="mat-form-field" appearance="outline">
                <input formControlName="devicesDescriptionPdf" matInput placeholder="{{'devicesDescriptionPdf' | translate}}">
            </mat-form-field>
        </div>

        <div class="additional-fields__wrapper__item">
            <label>{{ 'entryDescriptionPdfLabel' | translate }}</label>
            <mat-form-field class="mat-form-field" appearance="outline">
                <input formControlName="entryDescriptionPdf" matInput placeholder="{{'entryDescriptionPdf' | translate}}">
            </mat-form-field>
        </div>

        <div class="additional-fields__wrapper__item">
            <label>{{ 'editingPdfLabel' | translate }}</label>
            <mat-form-field class="mat-form-field" appearance="outline">
                <input formControlName="editingPdf" matInput placeholder="{{'editingPdf' | translate}}">
            </mat-form-field>
        </div>

        <div class="additional-fields__wrapper__item">
            <label>{{ 'durationMinutesPdfLabel' | translate }}</label>
            <mat-form-field class="mat-form-field" appearance="outline">
                <input
                   formControlName="durationMinutesPdf"
                   matInput
                   placeholder="{{'durationMinutesPdf' | translate}}"
                >
            </mat-form-field>
        </div>

<!--        <div class="additional-fields__wrapper__item">-->
<!--            <label>{{ 'resultsPdfLabel' | translate }}</label>-->
<!--            <mat-form-field class="mat-form-field" appearance="outline">-->
<!--                <input formControlName="resultsPdf" matInput placeholder="{{'resultsPdf' | translate}}">-->
<!--            </mat-form-field>-->
<!--        </div>-->

<!--        <div class="additional-fields__wrapper__item">-->
<!--            <label>{{ 'afterQRCodeDescriptionPdfLabel' | translate }}</label>-->
<!--            <mat-form-field class="mat-form-field" appearance="outline">-->
<!--                <input formControlName="afterQRCodeDescriptionPdf" matInput placeholder="{{'afterQRCodeDescriptionPdf' | translate}}">-->
<!--            </mat-form-field>-->
<!--        </div>-->

<!--        <div class="additional-fields__wrapper__item">-->
<!--            <label>{{ 'fullNamePdfLabel' | translate }}</label>-->
<!--            <mat-form-field class="mat-form-field" appearance="outline">-->
<!--                <input formControlName="fullNamePdf" matInput placeholder="{{'fullNamePdf' | translate}}">-->
<!--            </mat-form-field>-->
<!--        </div>-->

<!--        <div class="additional-fields__wrapper__item">-->
<!--            <label>{{ 'personPositionPdfLabel' | translate }}</label>-->
<!--            <mat-form-field class="mat-form-field" appearance="outline">-->
<!--                <input formControlName="personPositionPdf" matInput placeholder="{{'personPositionPdf' | translate}}">-->
<!--            </mat-form-field>-->
<!--        </div>-->

<!--        <div class="additional-fields__wrapper__item">-->
<!--            <label>{{ 'farewellTextPdfLabel' | translate }}</label>-->
<!--            <mat-form-field class="mat-form-field" appearance="outline">-->
<!--                <input formControlName="farewellTextPdf" matInput placeholder="{{'farewellTextPdf' | translate}}">-->
<!--            </mat-form-field>-->
<!--        </div>-->

        <div class="additional-fields__wrapper__item contact-text-area">
            <label>{{ 'contactPersonPdfLabel' | translate }}</label>
            <mat-form-field appearance="outline" class="textAreaFormField">
                <textarea formControlName="contactPersonPdf" matInput placeholder="{{'contactPersonPdf' | translate}}"></textarea>
            </mat-form-field>

<!--            <mat-form-field class="mat-form-field" appearance="outline">-->
<!--                <input formControlName="contactPersonPdf" matInput placeholder="{{'contactPersonPdf' | translate}}">-->
<!--            </mat-form-field>-->
        </div>
    </div>

    <button
        class="additional-fields__wrapper__button"
        mat-fab
        extended
        (click)="generatePdfForPreview($event)"
    >{{ 'checkPdfPreview' | translate }}</button>
</div>