import { Component, OnDestroy, OnInit } from "@angular/core";
import { ResultService } from "../../../services/result.service";
import { EvaluationService } from "../../../services/evaluation.service";
import { Model } from "survey-core";
import { ResultTestFiltersService } from "../../../services/result-test-filters.service";
import { BehaviorSubject } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Evaluation } from "../../../model/Evaluation";
import { PdfService } from "../../../services/pdfService";
import { OrganizationService } from "../../../services/organization.service";
import { Organization } from "../../../model/Organization";
import { VisualizationPanel } from "survey-analytics";

export const ENTITIES = ["CUSTOMER", "EMPLOYEE", "MANAGEMENT", "TEAM"];

@Component({
  selector: "app-result-test",
  templateUrl: "./result-test.component.html",
  styleUrls: ["./result-test.component.css"],
})
export class ResultTestComponent implements OnInit, OnDestroy {
  currentLanguage: string = "de";
  categories: any[] = [];
  displayedColumns: string[] = [];
  dataSource: Array<any> = [];
  graphDataAll: any = [];
  filterDataForPdf: {
    facilityNames: Array<string>;
    tokenTypeNames: Array<string>;
  };
  dataSourceOpenAnswers: any = [];
  columnsOpenAnswers: any = [];
  translatedText = "";
  vizPanelOptions = {
    haveCommercialLicense: true,
  };

  surveyWithOpenQuestions: Model;
  vizPanel: VisualizationPanel;

  isPdfLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  surveyCommon: Model;

  evaluationId: string | null;

  currentEvaluation: BehaviorSubject<Evaluation | null> =
    new BehaviorSubject<Evaluation | null>(null);

  evaluationSummary: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  currentOrganization: BehaviorSubject<Organization | null> =
    new BehaviorSubject<Organization | null>(null);

  plotlyConfiguration = {
    config: {
      displayModeBar: false,
    },
  };
  tokenTypeNames;
  facilityNames;

  constructor(
    private resultService: ResultService,
    private evaluationService: EvaluationService,
    private organizationService: OrganizationService,
    public resultTestFilterService: ResultTestFiltersService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private pdfService: PdfService,
  ) {}

  ngOnInit() {
    this.getEvaluationId();
    if (this.evaluationId) {
      this.evaluationService
        .getEvaluationById(this.evaluationId)
        .subscribe((evaluation) => {
          const currentLanguage = localStorage.getItem("lang") || "de";
          const surveyCategories = evaluation?.customSurvey?.pages?.map(
            (page) => ({
              title: page?.title?.[currentLanguage],
              id: page?.id,
              questions: page?.questions?.map((question) => ({
                id: question?.id,
                //@TODO strange wish of customer
                title: question?.title,
                indicator: question?.indicator,
              })),
              graphDataAll: [],
            }),
          );

          this.currentLanguage = currentLanguage;
          this.categories = surveyCategories;
          this.getGeneralResults(
            String(evaluation?.id),
            evaluation?.facilities,
          );
          this.currentEvaluation.next(evaluation);
          this.getResultAndRender();

          this.tokenTypeNames = ENTITIES;
          const facilitiesForPdf = (evaluation?.facilities.map(
            (item) => item.facility_name,
          ) || []) as unknown as Array<string>;
          this.facilityNames = facilitiesForPdf as unknown as Array<string>;
          this.filterDataForPdf = {
            facilityNames: facilitiesForPdf,
            tokenTypeNames: ENTITIES,
          };
        })
        .add(() => {
          // if (this.currentEvaluation.value?.organization_id) {
          //@TODO hardcoded, need to be fixed
          this.organizationService
            .getOrganizationById(
              this.currentEvaluation.value?.organization_id || "1",
            )
            .subscribe((organization) => {
              this.currentOrganization.next(organization);
            });
          // }
        });

      this.evaluationService
        .getEvaluationSummaryById(this.evaluationId)
        .subscribe((summary) => {
          this.evaluationSummary.next(summary);
        });
    }

    this.translate.get("category").subscribe((translation: string) => {
      this.translatedText = translation;
    });
  }

  ngOnDestroy() {
    this.evaluationId = null;
    this.graphDataAll = [];
  }

  private togglePdfLoading(value: boolean) {
    this.isPdfLoading.next(value);
  }

  getEvaluationId() {
    this.evaluationId = this.route.snapshot.paramMap.get("id");
  }

  getDataFromQuestionsDiagram(question: any) {
    const result = [];
    for (const key in question) {
      //@ts-ignore
      if (key !== "layout") result.push(question[key]);
    }
    return result;
  }

  getGeneralResults(evaluationId: string, facilities: Array<any>) {
    this.resultService
      .getEvaluationResultsGeneral(evaluationId, facilities)
      .subscribe((res) => {
        const allKeys = new Set();
        Object.keys(res).forEach((result) => {
          Object.keys(res[result]).forEach((key) => {
            allKeys.add(key);
          });
        });

        let finalDataSource: Array<any> = [];

        this.categories?.forEach((category) => {
          const categoryRow: any = {
            "Question Indicator": `${this.translatedText}: ${category?.title}`,
          };
          categoryRow["Total Average"] = "";
          Object.entries(res).forEach(([facility]: any) => {
            // const rowValue = result[key as any] || 'Not answered';
            categoryRow[facility] = "";
            // if (rowValue !== "Not answered") {
            //     intermidiateSum = Number(intermidiateSum) + Number(rowValue)
            // } else {
            //     totalFacilitiesWithoutNotAnswered -= 1;
            // }
            // row["Total Average"] = intermidiateSum;
            //
            // if (index === totalFacilities - 1) {
            //     row["Total Average"] = (intermidiateSum / totalFacilitiesWithoutNotAnswered).toFixed(2)
            // }
          });

          finalDataSource.push(categoryRow);

          category?.questions
            ?.map((question) => question?.indicator)
            ?.forEach((key) => {
              const row: any = { "Question Indicator": key };
              let intermidiateSum = 0;
              let totalFacilities = Object.entries(res)?.length;
              let totalFacilitiesWithoutNotAnswered = totalFacilities;
              Object.entries(res).forEach(([facility, result]: any, index) => {
                const rowValue = result[key as any] || "Not answered";
                row[facility] = rowValue;
                if (rowValue !== "Not answered") {
                  intermidiateSum = Number(intermidiateSum) + Number(rowValue);
                } else {
                  totalFacilitiesWithoutNotAnswered -= 1;
                }
                row["Total Average"] = intermidiateSum;

                if (index === totalFacilities - 1) {
                  row["Total Average"] = (
                    intermidiateSum / totalFacilitiesWithoutNotAnswered
                  ).toFixed(2);
                }
              });

              if (row["Total Average"] !== "NaN") {
                finalDataSource.push(row);
              }
            });
        });

        this.dataSource = finalDataSource || [];

        this.displayedColumns = ["Question Indicator"]
          .concat(Object.keys(res))
          .concat(["Total Average"]);
      });
  }

  filterByTextObj(obj: Record<string, string>) {
    let result = {};
    for (let key in obj) {
      if (isNaN(Number(obj[key]))) {
        result[key] = obj[key];
      }
    }
    return result;
  }

  getResultAndRender() {
    this.resultTestFilterService.filters$.subscribe((filters) => {
      this?.categories?.forEach((category) => {
        if (category?.graphDataAll) category.graphDataAll = [];
      });
      this.graphDataAll = [];
      this.resultService
        .getEvaluationResultsNew(Number(this.evaluationId), filters)
        .subscribe((res) => {
          const oldSurvey = this.evaluationService.mapOldSurvey(
            this.currentEvaluation?.value?.customSurvey,
          );
          this.surveyCommon = new Model(oldSurvey);

          let resultsWithOpenAnswer: Array<any> = [];

          for (const key in res) {
            res[key]?.forEach((answer) => {
              const openAnswerCandidate = this.filterByTextObj(answer);
              if (Object.keys(openAnswerCandidate)?.length) {
                resultsWithOpenAnswer.push(openAnswerCandidate);
              }
            });
          }

          this.surveyCommon.getAllQuestions()?.forEach((item) => {
            //@TODO understand what to do with different types of questions when they are in place
            const type = item?.getType() === "radiogroup" ? "bar" : "table";
            const choices = item?.["choices"]?.map((el) => el?.text) || [];
            if (type !== "table") {
              this.graphDataAll.push({
                EMPLOYEE: {
                  x: choices,
                  type,
                },
                CUSTOMER: {
                  x: choices,
                  type,
                },
                MANAGEMENT: {
                  x: choices,
                  type,
                },
                TEAM: {
                  x: choices,
                  type,
                },
                layout: {
                  // barnorm: 'percent',
                  id: Number(item?.name),
                  title: {
                    text: item?.["title"],
                    font: {
                      size: 16,
                      weight: "bold",
                    },
                  },
                  xaxis: { title: "" },
                  yaxis: { title: "" },
                  plot_bgcolor: "#f7f7f7",
                  paper_bgcolor: "#f7f7f7",
                },
              });
            } else {
              const candidates = resultsWithOpenAnswer
                ?.filter((el) => el?.[item?.name])
                ?.map((el) => ({ [item?.name]: el?.[item?.name] }));
              this.graphDataAll.push({
                type,
                dataSource: candidates,
                displayedColumns: [item?.name],
                layout: {
                  // barnorm: 'percent',
                  id: Number(item?.name),
                  title: {
                    text: item?.["title"],
                    font: {
                      size: 16,
                      weight: "bold",
                    },
                  },
                  xaxis: { title: "" },
                  yaxis: { title: "" },
                  plot_bgcolor: "#f7f7f7",
                  paper_bgcolor: "#f7f7f7",
                },
              });
            }
          });

          const surveyWithOpenAnswersOnly =
            this.evaluationService.getQuestionsWithOpenAnswers(
              this.currentEvaluation?.value?.customSurvey,
            );

          for (const key in res) {
            this.surveyCommon.getAllQuestions()?.forEach((item, index) => {
              const intermediateResult = {};
              res[key]?.forEach((el) => {
                if (intermediateResult[el?.[item?.name]]) {
                  intermediateResult[el?.[item?.name]] += 1;
                } else {
                  intermediateResult[el?.[item?.name]] = 1;
                }
              });

              const answers = item?.["choices"]?.map((el) => {
                if (
                  Object.keys(intermediateResult)?.some(
                    (item) => item === el?.value,
                  )
                ) {
                  return intermediateResult[el?.value];
                } else return 0;
              });

              const answersTotal = answers?.reduce?.((el, acc) => el + acc, 0);

              const answersPercent = answers?.map(
                (el) => `${((el / answersTotal) * 100).toFixed(2)}%`,
              );

              this.graphDataAll[index] = {
                ...this.graphDataAll[index],
                [key]: {
                  ...this.graphDataAll[index]?.[key],
                  y: answers,
                  text: answersPercent,
                  name:
                    this?.currentEvaluation?.value?.tokenDictionary?.[key] ||
                    key,
                },
              };
            });
          }

          this.graphDataAll?.forEach((graphDataEl) => {
            const graphDataElToShow = { ...graphDataEl };

            if (graphDataEl?.type !== "table") {
              Object.keys(graphDataElToShow)?.forEach((graphDataElKey) => {
                if (
                  !graphDataElToShow?.[graphDataElKey]?.["y"] &&
                  graphDataElKey !== "layout"
                ) {
                  delete graphDataElToShow?.[graphDataElKey];
                }
              });
            }
            let currentCategory;

            this.categories?.forEach((category) => {
              if (
                category?.questions?.some(
                  (question) => question?.id == graphDataEl?.layout?.id,
                )
              ) {
                currentCategory = category;
              }
            });

            if (currentCategory?.graphDataAll) {
              currentCategory.graphDataAll.push(graphDataElToShow);
            }
          });

          //@TODO this logic is not actual anymore
          if (resultsWithOpenAnswer?.length) {
            this.dataSourceOpenAnswers = resultsWithOpenAnswer;
            this.columnsOpenAnswers = surveyWithOpenAnswersOnly?.pages?.reduce(
              (acc, page) => {
                acc = [...acc, ...page?.elements];
                return acc;
              },
              [],
            );
          }
          //     if (this.vizPanel) this.vizPanel.destroy();
          //     this.surveyWithOpenQuestions = new Model(surveyWithOpenAnswersOnly);
          //     this.vizPanel = new VisualizationPanel(
          //         this.surveyWithOpenQuestions.getAllQuestions(),
          //         resultsWithOpenAnswer,
          //         this.vizPanelOptions
          //     );
          //     this.vizPanel.render("surveyVizPanel");
          //
          //
          //     // console.log("surveyWithOpenAnswersOnly elements", surveyWithOpenAnswersOnly?.pages?.map((page) => ([...page.elements])))
          //     this.dataSourceOpenAnswers = resultsWithOpenAnswer;
          //     this.columnsOpenAnswers = surveyWithOpenAnswersOnly?.pages?.reduce((acc, page) => {
          //         acc = [...acc, ...page?.elements];
          //         return acc;
          //     }, []);
          // } else {
          //     if (this.vizPanel) this.vizPanel.destroy();
          // }
        });

      console.log("this.catgrorires updated>>>", this.categories);
    });
  }

  checkCategoryByTitle(text: string) {
    return text.includes(this.translatedText);
  }

  getFiltersData(event: any) {
    const { entities, facilityIds } = event;

    const defaultFacilities = this?.currentEvaluation?.value?.facilities;

    if (facilityIds.length) {
      this.facilityNames = defaultFacilities
        ?.filter((item) => facilityIds.includes(item.facility_id))
        .map((item) => item.facility_name);
    } else {
      this.facilityNames = defaultFacilities?.map((item) => item.facility_name);
    }

    const dictionaryTypes =
      this?.currentEvaluation?.value?.tokenDictionary || {};

    if (entities.length) {
      let filteredTypeNames = ENTITIES.filter((typeName) =>
        entities.includes(typeName),
      );
      if (Object.entries(dictionaryTypes).length) {
        filteredTypeNames = filteredTypeNames.map(
          (typeName) => dictionaryTypes[typeName] ?? typeName,
        );
      }
      this.tokenTypeNames = filteredTypeNames;
    } else {
      if (Object.entries(dictionaryTypes).length) {
        this.tokenTypeNames = ENTITIES.map(
          (typeName) => dictionaryTypes[typeName] ?? typeName,
        );
      } else {
        this.tokenTypeNames = ENTITIES;
      }
    }

    this.filterDataForPdf = {
      facilityNames: this.facilityNames,
      tokenTypeNames: this.tokenTypeNames,
    };
  }

  handleExport() {
    this.pdfService.generateResultPDF({
      filtersData: this.filterDataForPdf,
      togglePdfLoading: this.togglePdfLoading.bind(this),
      currentEvaluation: this.currentEvaluation.value,
      graphDataAll: this.graphDataAll,
      evaluationSummary: this.evaluationSummary.value,
      organizationTitle: this.currentOrganization?.value?.name,
      categories: this.categories,
      dataSourceGeneral: this.dataSource,
      columnsGeneral: this.displayedColumns,
      dataSourceOpenAnswers: this.dataSourceOpenAnswers,
      columnsOpenAnswers: this.columnsOpenAnswers,
    });
  }
}
