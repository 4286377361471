import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ResultTestFiltersService } from "../../../../services/result-test-filters.service";
import { BehaviorSubject } from "rxjs";
import { Evaluation } from "../../../../model/Evaluation";

export const ENTITIES = ["CUSTOMER", "EMPLOYEE", "MANAGEMENT", "TEAM"];

@Component({
  selector: "result-test-filters",
  templateUrl: "result-test-filters.component.html",
  styleUrls: ["result-test-filters.component.css"],
})
export class ResultTestFiltersComponent implements OnInit {
  @Input() currentEvaluation: BehaviorSubject<Evaluation | null>;
  @Input() facilities: Array<any>;
  @Input() tokenDictionary?: Record<string, string>;
  @Output() filtersData = new EventEmitter<any>();

  constructor(private filterService: ResultTestFiltersService) {}

  ngOnInit(): void {
    this?.currentEvaluation.subscribe((evaluation) => {
      //@TODO REFACTOR LATER
      if (evaluation?.id == 135) {
        this.entities = ["MANAGEMENT"];
      }
    });
  }

  updateFacilityFilters(facilityId: number, isChecked: boolean): void {
    const currentFilters = this.filterService.filters$.getValue();
    if (isChecked) {
      currentFilters.facilityIds.push(facilityId);
    } else {
      currentFilters.facilityIds = currentFilters.facilityIds.filter(
        (id) => id !== facilityId,
      );
    }
    this.filterService.filters$.next(currentFilters);
    this.filtersData.emit(currentFilters);
  }

  updateEntityFilters(entity: string, isChecked: boolean): void {
    const currentFilters = this.filterService.filters$.getValue();
    if (isChecked) {
      currentFilters.entities.push(entity);
    } else {
      currentFilters.entities = currentFilters.entities.filter(
        (e) => e !== entity,
      );
    }
    this.filterService.filters$.next(currentFilters);
    this.filtersData.emit(currentFilters); // Emit the updated filters
  }

  protected entities = ENTITIES;
}
