import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { ResultRequestFilters } from "../model/ResultRequestFilters";

@Injectable({
  providedIn: "root",
})
export class ResultTestFiltersService {
  private pendingState = new Subject<boolean>();

  public filters$: BehaviorSubject<ResultRequestFilters> =
    new BehaviorSubject<ResultRequestFilters>({
      facilityIds: [],
      entities: [],
    });
  constructor() {}

  setPendingState(isPending: boolean) {
    this.pendingState.next(isPending);
  }

  getPendingState() {
    return this.pendingState.asObservable();
  }
}
