<div id="modal-content-wrapper">
    <section id="modal-body" class="modal-body">
        <div class="container" *ngIf="this.language">
            <div *ngIf="!!evaluationLogo" class="logo-container">
                <img class="logo" alt="logo" [src]="evaluationLogo" />
            </div>
            <div class="title-container">
                <h1 class="title-text">{{this.evaluationTitle}}</h1>
            </div>
            <div class="sub-container">
                <form [formGroup]="resultsForm">
                    <div class="survey-container" id="survey-container">
                        <mat-tab-group color="primary" [selectedIndex]="selectedIndex" (selectedTabChange)="onTabChange($event)">
                            <mat-tab *ngFor="let page of this.survey.pages">
                                <ng-template mat-tab-label>{{page.title[this.language]}}</ng-template>
                                <div>
                                    <div *ngFor="let question of page.questions" class="question-container">
                                        <mat-expansion-panel
                                            class="expansion-panel"
                                            (opened)="panelOpenState = true"
                                            (closed)="panelOpenState = false"
                                            [disabled]="!question.tips[this.language]"
                                        >
                                            <mat-expansion-panel-header class="section-header">
                                                <mat-panel-title>
                                                    {{question.title[this.selectedToken][this.language || 'de']}}
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div class="tip">
                                                {{question.tips[this.language]}}
                                            </div>
                                        </mat-expansion-panel>
                                        <div class="options-container">
                                            <div *ngIf="question.type === 'RADIO' || question.type === 'BOOLEAN' ">
                                                <mat-radio-group color="primary" formControlName="{{question.id}}">
                                                    <div *ngFor="let option of question.options">
                                                        <mat-radio-button [value]="option.value">
                                                            <span class="radio-button-text">{{option.title[this.language]}}</span>
                                                        </mat-radio-button>
                                                    </div>
                                                </mat-radio-group>
                                            </div>

                                            <div *ngIf="question.type === 'TEXT' ">
                                                <mat-form-field appearance="outline" class="textAreaFormField">
                                                    <textarea formControlName="{{question.id}}" matInput width="100%"></textarea>
                                                </mat-form-field>
                                            </div>
                                            <div class="slider-container" *ngIf="question.type === 'RANGE' ">
                                                <span class="slider-limits-text">1</span>
                                                <mat-slider
                                                        color="primary"
                                                        [max]="(6)"
                                                        [min]="(1)"
                                                        [step]="(1)"
                                                        [discrete]="true"
                                                        [showTickMarks]="true">
                                                    <input matSliderThumb formControlName="{{question.id}}">
                                                </mat-slider>
                                                <span class="slider-limits-text">6</span>
                                            </div>
<!--                                            <div *ngIf="question.type === 'MULTISELECT'" formArrayName="{{ question.id }}">-->
<!--                                                <section>-->
<!--                                                    <div *ngFor="let option of question.options; let i = index">-->
<!--                                                        <mat-checkbox [value]="option.value" [formControlName]="i"-->
<!--                                                                      (change)="onCheckboxChange($event.checked, question.id, option.value)">{{option.title[this.language]}}</mat-checkbox>-->
<!--                                                    </div>-->
<!--                                                </section>-->
<!--                                            </div>-->
                                        </div>

                                    </div>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </form>
            </div>
        </div>
        <div>
            <div class="languageSelect" >
                <mat-form-field appearance="outline" >
                    <mat-icon matIconPrefix>language</mat-icon>

                    <mat-select [(ngModel)]="selected" (ngModelChange)="selectLanguage()">
                        <mat-option lang="de" value="de">Deutsch</mat-option>
                        <mat-option lang="en" value="en">English</mat-option>
                        <mat-option lang="ar" value="ar">عرب</mat-option>
                        <mat-option lang="uk" value="uk">Українська</mat-option>
                        <mat-option lang="ru" value="ru">Русский</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="languageSelect" >
                <mat-form-field appearance="outline" >
                    <mat-select
                        [(ngModel)]="selectedToken"
                        (ngModelChange)="selectToken()"
                    >
                        <mat-option *ngFor="let option of currentTokens" [value]="option.code"> {{ option?.label }} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </section>
    <footer id="modal-footer">
    </footer>
</div>