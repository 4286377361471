<label for="field" class="field-label-2" >{{'loginWithEmail' | translate}}</label>
<input type="text" [(ngModel)]="email" class="textfield w-input" maxlength="256" name="field" data-name="Field" placeholder="{{'email' | translate }}" id="field" required="true">
<label for="field1" class="field-label-2"></label>
<div class="password-input">
    <input
         type="{{isPasswordHidden ?'password': 'text'}}"
         [(ngModel)]="password"
         class="textfield password-input-field"
         maxlength="256"
         name="field"
         data-name="Field"
         placeholder="{{'password' | translate}}"
         id="field1"
         required="true"
    >
        <span
          class="material-icons eye-button"
          (click)="onEyeClick()"
        >
          {{isPasswordHidden ? 'visibility' : 'visibility_off'}}
        </span>
</div>

<div class="w-layout-hflex flex-block">
    <div >
        <button type="button" class="prim-button w-inline-block buttonLogin" (click)="loginViaEmail()" >{{'login' | translate}}</button>
    </div>
</div>