export const SURVEY = {
  creationDate: "08.04.2024",
  description: {
    ru: "placeholder",
    en: "placeholder",
    de: "Diese Umfrage dient zur Erstellung eines Stimmungsbarometers und dient zur kontinuierlichen Bewertung interner Prozesse und Vorgaben. Dies ist ein Textbeispiel und dieses kann individuell für jede Umfrage vom Ersteller definiert werden",
    ar: "placeholderً",
    uk: "placeholder",
  },

  pages: [
    {
      value: "1",
      title: {
        ru: "placeholder",
        en: "placeholder",
        de: "Allgemein",
        ar: "placeholder",
        uk: "placeholder",
      },
      questions: [
        {
          type: "radio",
          indicator: "1",
          id: "1",
          tip: {
            ru: "placeholder",
            en: "placeholder",
            de: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
            ar: "placeholder",
            uk: "placeholder",
          },
          title: {
            employee: {
              ru: "placeholder ",
              en: "placeholder",
              de: "Wie zufrieden sind Sie mit der Kommunikation und Transparenz innerhalb Ihrer Abteilung? (employee)",
              ar: "placeholder",
              uk: "placeholder",
            },
            CUSTOMER: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie zufrieden sind Sie mit der Kommunikation und Transparenz innerhalb Ihrer Abteilung (customer)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            management: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie zufrieden sind Sie mit der Kommunikation und Transparenz innerhalb Ihrer Abteilung (manager)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            team: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie zufrieden sind Sie mit der Kommunikation und Transparenz innerhalb Ihrer Abteilung (team member)?",
              ar: "placeholder",
              uk: "placeholder",
            },
          },
          settings: {
            min: null,
            max: null,
            thumbLabel: null,
            step: null,
            showTicks: null,
          },
          options: [
            {
              value: "answer1",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft vollumfänglich zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer2",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft überwiegend zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer3",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft eher mehr zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer4",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft nicht zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
          ],
        },
        {
          type: "radio",
          indicator: "2",
          id: "2",
          tip: {
            ru: "placeholder",
            en: "placeholder",
            de: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
            ar: "placeholder",
            uk: "placeholder",
          },
          title: {
            employee: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie gut funktioniert die Zusammenarbeit zwischen den Teammitgliedern in Ihrer Abteilung (employee)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            CUSTOMER: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie gut funktioniert die Zusammenarbeit zwischen den Teammitgliedern in Ihrer Abteilung (customer)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            management: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie gut funktioniert die Zusammenarbeit zwischen den Teammitgliedern in Ihrer Abteilung (manager)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            team: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie gut funktioniert die Zusammenarbeit zwischen den Teammitgliedern in Ihrer Abteilung (team member)?",
              ar: "placeholder",
              uk: "placeholder",
            },
          },
          settings: {
            min: null,
            max: null,
            thumbLabel: null,
            step: null,
            showTicks: null,
          },
          options: [
            {
              value: "answer1",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft vollumfänglich zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer2",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft überwiegend zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer3",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft eher mehr zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer4",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft nicht zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
          ],
        },
        {
          type: "bool",
          indicator: "3",
          id: "3",
          tip: {
            ru: "placeholder",
            en: "placeholder",
            de: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
            ar: "placeholder",
            uk: "placeholder",
          },
          title: {
            employee: {
              ru: "placeholder",
              en: "placeholder",
              de: "Sind Sie zufrieden mit den Arbeitsbedingungen und der Ausstattung in Ihrer Abteilung (employee)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            CUSTOMER: {
              ru: "placeholder",
              en: "placeholder",
              de: "Sind Sie zufrieden mit den Arbeitsbedingungen und der Ausstattung in Ihrer Abteilung (customer)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            management: {
              ru: "placeholder",
              en: "placeholder",
              de: "Sind Sie zufrieden mit den Arbeitsbedingungen und der Ausstattung in Ihrer Abteilung (manager)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            team: {
              ru: "placeholder",
              en: "placeholder",
              de: "Sind Sie zufrieden mit den Arbeitsbedingungen und der Ausstattung in Ihrer Abteilung (team member)?",
              ar: "placeholder",
              uk: "placeholder",
            },
          },
          settings: {
            min: null,
            max: null,
            thumbLabel: null,
            step: null,
            showTicks: null,
          },
          options: [
            {
              value: "answer1",
              title: {
                ru: "да",
                en: "yes",
                de: "Ja",
                ar: "نعم",
                uk: "Так",
              },
            },
            {
              value: "answer2",
              title: {
                ru: "нет",
                en: "no",
                de: "Nein",
                ar: "لا يوجد",
                uk: "Ні",
              },
            },
          ],
        },
      ],
    },
    {
      value: "2",
      title: {
        ru: "placeholder",
        en: "placeholder",
        de: "Arbeitsalltag",
        ar: "placeholder",
        uk: "placeholder",
      },
      questions: [
        {
          type: "range",
          indicator: "4",
          id: "4",
          tip: {
            ru: "placeholder",
            en: "placeholder",
            de: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
            ar: "placeholder",
            uk: "placeholder",
          },
          title: {
            employee: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie beurteilen Sie die Leitung in Ihrer Abteilung von 0 bis 10, sind Sie zufrieden und ist der Vorgesetzte ansprechbar und hilfsbereit (employee)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            CUSTOMER: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie beurteilen Sie die Leitung in Ihrer Abteilung von 0 bis 10, sind Sie zufrieden und ist der Vorgesetzte ansprechbar und hilfsbereit (customer)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            management: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie beurteilen Sie die Leitung in Ihrer Abteilung von 0 bis 10, sind Sie zufrieden und ist der Vorgesetzte ansprechbar und hilfsbereit (manager)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            team: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie beurteilen Sie die Leitung in Ihrer Abteilung von 0 bis 10, sind Sie zufrieden und ist der Vorgesetzte ansprechbar und hilfsbereit (team member)?",
              ar: "placeholder",
              uk: "placeholder",
            },
          },
          settings: {
            min: 0,
            max: 20,
            thumbLabel: true,
            step: 1,
            showTicks: false,
          },
          options: [],
        },
        {
          type: "multiselect",
          indicator: "5",
          id: "5",
          tip: {
            ru: "placeholder",
            en: "placeholder",
            de: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
            ar: "placeholder",
            uk: "placeholder",
          },
          title: {
            employee: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie zufrieden sind Sie mit den Entwicklungsmöglichkeiten und Weiterbildungsangeboten in Ihrer Abteilung (employee)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            CUSTOMER: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie zufrieden sind Sie mit den Entwicklungsmöglichkeiten und Weiterbildungsangeboten in Ihrer Abteilung (customer)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            management: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie zufrieden sind Sie mit den Entwicklungsmöglichkeiten und Weiterbildungsangeboten in Ihrer Abteilung (manager)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            team: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie zufrieden sind Sie mit den Entwicklungsmöglichkeiten und Weiterbildungsangeboten in Ihrer Abteilung (team member)?",
              ar: "placeholder",
              uk: "placeholder",
            },
          },
          settings: {
            min: null,
            max: null,
            thumbLabel: null,
            step: null,
            showTicks: null,
          },
          options: [
            {
              value: "answer1",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft vollumfänglich zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer2",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft überwiegend zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer3",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft eher mehr zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer4",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft nicht zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
          ],
        },
        {
          type: "radio",
          indicator: "6",
          id: "6",
          tip: {
            ru: "placeholder",
            en: "placeholder",
            de: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
            ar: "placeholder",
            uk: "placeholder",
          },
          title: {
            employee: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie gut werden Ihre Leistungen in Ihrer Abteilung anerkannt und belohnt (employee)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            CUSTOMER: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie gut werden Ihre Leistungen in Ihrer Abteilung anerkannt und belohnt (customer)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            management: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie gut werden Ihre Leistungen in Ihrer Abteilung anerkannt und belohnt (manager)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            team: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie gut werden Ihre Leistungen in Ihrer Abteilung anerkannt und belohnt (team member)?",
              ar: "placeholder",
              uk: "placeholder",
            },
          },
          settings: {
            min: null,
            max: null,
            thumbLabel: null,
            step: null,
            showTicks: null,
          },
          options: [
            {
              value: "answer1",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft vollumfänglich zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer2",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft überwiegend zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer3",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft eher mehr zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer4",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft nicht zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
          ],
        },
        {
          type: "radio",
          indicator: "7",
          id: "7",
          tip: {
            ru: "placeholder",
            en: "placeholder",
            de: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
            ar: "placeholder",
            uk: "placeholder",
          },
          title: {
            employee: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie zufrieden sind Sie mit der Work-Life-Balance in Ihrer Abteilung?(employee)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            CUSTOMER: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie zufrieden sind Sie mit der Work-Life-Balance in Ihrer Abteilung? (customer)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            management: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie zufrieden sind Sie mit der Work-Life-Balance in Ihrer Abteilung? (manager)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            team: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie zufrieden sind Sie mit der Work-Life-Balance in Ihrer Abteilung? (team member)?",
              ar: "placeholder",
              uk: "placeholder",
            },
          },
          settings: {
            min: null,
            max: null,
            thumbLabel: null,
            step: null,
            showTicks: null,
          },
          options: [
            {
              value: "answer1",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft vollumfänglich zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer2",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft überwiegend zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer3",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft eher mehr zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer4",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft nicht zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
          ],
        },
        {
          type: "radio",
          indicator: "8",
          id: "8",
          tip: {
            ru: "placeholder",
            en: "placeholder",
            de: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
            ar: "placeholder",
            uk: "placeholder",
          },
          title: {
            employee: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie gut werden Ihre Ideen und Vorschläge in Ihrer Abteilung berücksichtigt (employee)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            CUSTOMER: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie gut werden Ihre Ideen und Vorschläge in Ihrer Abteilung berücksichtigt (customer)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            management: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie gut werden Ihre Ideen und Vorschläge in Ihrer Abteilung berücksichtigt (manager)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            team: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie gut werden Ihre Ideen und Vorschläge in Ihrer Abteilung berücksichtigt (team member)?",
              ar: "placeholder",
              uk: "placeholder",
            },
          },
          settings: {
            min: null,
            max: null,
            thumbLabel: null,
            step: null,
            showTicks: null,
          },
          options: [
            {
              value: "answer1",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft vollumfänglich zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer2",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft überwiegend zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer3",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft eher mehr zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer4",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft nicht zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
          ],
        },
        {
          type: "radio",
          indicator: "9",
          id: "9",
          tip: {
            ru: "placeholder",
            en: "placeholder",
            de: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
            ar: "placeholder",
            uk: "placeholder",
          },
          title: {
            employee: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie zufrieden sind Sie mit der Unterstützung und dem Feedback, das Sie von Ihrem Vorgesetzten erhalten (employee)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            CUSTOMER: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie zufrieden sind Sie mit der Unterstützung und dem Feedback, das Sie von Ihrem Vorgesetzten erhalten (customer)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            management: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie zufrieden sind Sie mit der Unterstützung und dem Feedback, das Sie von Ihrem Vorgesetzten erhalten (manager)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            team: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie zufrieden sind Sie mit der Unterstützung und dem Feedback, das Sie von Ihrem Vorgesetzten erhalten (team member)?",
              ar: "placeholder",
              uk: "placeholder",
            },
          },
          settings: {
            min: null,
            max: null,
            thumbLabel: null,
            step: null,
            showTicks: null,
          },
          options: [
            {
              value: "answer1",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft vollumfänglich zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer2",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft überwiegend zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer3",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft eher mehr zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer4",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft nicht zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
          ],
        },
        {
          type: "radio",
          indicator: "10",
          id: "10",
          tip: {
            ru: "placeholder",
            en: "placeholder",
            de: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
            ar: "placeholder",
            uk: "placeholder",
          },
          title: {
            employee: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie gut fühlen Sie sich in Ihrer Abteilung integriert und unterstützt (employee)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            CUSTOMER: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie gut fühlen Sie sich in Ihrer Abteilung integriert und unterstützt (customer)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            management: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie gut fühlen Sie sich in Ihrer Abteilung integriert und unterstützt (manager)?",
              ar: "placeholder",
              uk: "placeholder",
            },
            team: {
              ru: "placeholder",
              en: "placeholder",
              de: "Wie gut fühlen Sie sich in Ihrer Abteilung integriert und unterstützt (team member)?",
              ar: "placeholder",
              uk: "placeholder",
            },
          },
          settings: {
            min: null,
            max: null,
            thumbLabel: null,
            step: null,
            showTicks: null,
          },
          options: [
            {
              value: "answer1",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft vollumfänglich zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer2",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft überwiegend zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer3",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft eher mehr zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
            {
              value: "answer4",
              title: {
                ru: "placeholder",
                en: "placeholder",
                de: "Trifft nicht zu",
                ar: "placeholder",
                uk: "placeholder",
              },
            },
          ],
        },
      ],
    },
  ],
};
