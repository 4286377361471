import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, tap } from "rxjs";
import { AuthenticationService } from "./services/authentication.service";
import { Router } from "@angular/router";
import { EvaluationService } from "./services/evaluation.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public authentication: AuthenticationService,
    public evaluationService: EvaluationService,
    public router: Router,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const myToken = this.authentication.getToken();

    if (myToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // if (err.status === 401 || err.status === 404 ) {
            //   this.router.navigate([NavigationService.LOGINPAGE]).then()
            //
            //   // redirect to the login route
            //   // or show a modal
            // }
          }
        },
      ),
    );
  }
}
