import { Injectable } from "@angular/core";
import { DEFAULT_LOGO } from "../common/defaultLogo";
import { environment } from "../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { Evaluation } from "../model/Evaluation";
import { PlotlyService } from "angular-plotly.js";
import moment from "moment/moment";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

type PdfDataTokens = {
  logo: string;
  evaluationDescription: string;
  tokens: Array<{ code: string; type: string }>;
  evaluationId: string;
  facilityName: string;
  tokenDictionary?: Record<string, string>;
  descriptionPdf?: string;
  devicesDescriptionPdf?: string;
  entryDescriptionPdf?: string;
  editingPdf?: string;
  durationMinutesPdf?: string;
  resultsPdf?: string;
  afterQRCodeDescriptionPdf?: string;
  fullNamePdf?: string;
  personPositionPdf?: string;
  farewellTextPdf?: string;
  contactPersonPdf?: string;
  name?: string;
  startdate?: string;
  enddate?: string;
};

type PdfPayloadResult = {
  togglePdfLoading: (value: boolean) => void;
  organizationTitle?: string;
  currentEvaluation: Evaluation | null;
  graphDataAll: any[];
  evaluationSummary: Record<string, string>;
  categories: any[];
  dataSourceGeneral?: any;
  columnsGeneral?: any;
  dataSourceOpenAnswers?: any;
  columnsOpenAnswers?: any;
  filtersData: {
    facilityNames: Array<string>;
    tokenTypeNames: Array<string>;
  };
};

const defaultTokenDictionary = {
  CUSTOMER: "TYP_1",
  EMPLOYEE: "TYP_2",
  TEAM: "TYP_3",
  MANAGEMENT: "TYP_4",
};

@Injectable({
  providedIn: "root",
})
export class PdfService {
  constructor(
    private translate: TranslateService,
    private plotlyService: PlotlyService,
  ) {}

  public createTextBlock = (
    text: string,
    fontSize = 10,
    alignment = "left",
    color = "#000",
    bold = false,
    margin = [0, 5],
    hasPageBreak = false,
  ) => {
    return {
      text,
      fontSize,
      alignment,
      color,
      bold,
      margin,
      ...(hasPageBreak && {
        pageBreak: "before",
        style: "header",
      }),
    };
  };

  public createColumn = (
    leftText: string,
    rightText: string,
    bold = false,
  ) => ({
    columns: [
      [
        {
          text: leftText,
          margin: [0, 2],
          fontSize: 10,
          alignment: "left",
          bold,
        },
      ],
      [{ text: rightText, margin: [0, 2], fontSize: 10, alignment: "left" }],
    ],
  });

  public createQRCode = (url, evaluationId, token, qrCodeText) => ({
    columns: [
      [
        this.createTextBlock(
          "Wir brauchen Ihre Unterstützung!",
          10,
          "left",
          "#000",
          true,
          [0, 2],
        ),
        this.createTextBlock(
          "Machen Sie mit – beteiligen Sie sich an der Umfrage",
          10,
          "left",
          "#000",
          true,
          [0, 10, 10, 0],
        ),
        this.createTextBlock("Wie geht’s?", 10, "left", "#000", true, [0, 0]),
        {
          text: [
            this.createTextBlock(`QR-Code: `, 10, "left", "black", true),
            this.createTextBlock(
              `${qrCodeText || "Scannen Sie den beigefügten QR-Code und Sie gelangen direkt auf die Umfrage."}`,
            ),
          ],
          margin: [0, 5],
        },
      ],
      [
        {
          qr: `${url}?id=${evaluationId}&token=${token}`,
          alignment: "right",
          fit: "100",
        },
      ],
    ],
    margin: [0, 10],
  });

  public generateTokensPDF(data: PdfDataTokens) {
    //creating a pdf instance
    let docDefinition: any = {
      content: [],
      styles: {
        sectionHeader: {
          bold: true,
          decoration: "underline",
          fontSize: 14,
          margin: [0, 15, 0, 15],
        },
      },
    };

    //Filling a pdf instance with content (please check documentation: https://pdfmake.github.io/docs/0.1/)
    data.tokens.forEach((item, index) => {
      let tokenTitle = defaultTokenDictionary?.[item.type];
      if (data?.tokenDictionary?.[item.type]) {
        tokenTitle = data?.tokenDictionary?.[item.type];
      }
      docDefinition.content.push(
        ...[
          {
            stack: [
              [
                ...(data?.logo
                  ? [
                      {
                        image: data?.logo,
                        width: 200,
                        alignment: "center",
                        margin: [0, 20],
                      },
                    ]
                  : []),
              ],
              [
                this.createTextBlock(
                  `${data.facilityName}`,
                  15,
                  "left",
                  "#000",
                  true,
                  [0, 5],
                ),
              ],
              [
                this.createTextBlock(
                  `${tokenTitle} - Token`,
                  15,
                  "right",
                  "#000",
                  true,
                  [0, 5],
                ),
              ],
            ],
            pageBreak: index === 0 ? "none" : "before",
          },
          this.createTextBlock(
            data?.name || "",
            10,
            "center",
            "#FFA500",
            true,
            [0, 7],
          ),
          this.createTextBlock(
            "Liebe Beteiligte und Verantwortliche.",
            10,
            "left",
            "#000",
            true,
          ),
          {
            text: [
              this.createTextBlock(
                `Herzlich willkommen zur Umfrage: `,
                10,
                "left",
                "black",
                true,
              ),
              this.createTextBlock(
                `Alltagsintegrierte Sprachbildung/-förderung in der Kita.`,
              ),
            ],
            margin: [0, 5],
          },
          this.createTextBlock(
            "Mit unserer Umfrage möchten wir Ihre persönliche Einschätzung erhalten. Es gibt keine „richtigen“ oder „falschen“ Antworten auf die Fragen. Hinweise zur Nutzung finden Sie in den FAQ auf der Website.",
            10,
            "left",
            "#000",
            true,
          ),
          this.createColumn(
            "Zeitraum:",
            `${this.formatDate(data.startdate || "")} bis ${this.formatDate(data.enddate || "")} `,
            true,
          ),
          this.createColumn(
            "Art der Umfrage:",
            data?.devicesDescriptionPdf || "",
            true,
          ),
          this.createColumn("Zugang:", data?.entryDescriptionPdf || "", true),
          this.createColumn("Bearbeitung:", data?.editingPdf || "", true),
          this.createColumn("Umfang:", data?.durationMinutesPdf || "", true),
          // this.createColumn("Ergebnisse:", data?.resultsPdf || "", true),
          this.createQRCode(
            environment?.app_url,
            data.evaluationId,
            item?.code,
            data?.afterQRCodeDescriptionPdf,
          ),
          // this.createTextBlock(data?.afterQRCodeDescriptionPdf || ""),
          this.createTextBlock("Oder:", 10, "left", "#f30404"),

          {
            text: [
              this.createTextBlock(`Website: `, 10, "left", "black", true),
              this.createTextBlock(
                `Gehen Sie über den Browser ins Internet auf die Seite`,
              ),
            ],
            margin: [0, 5],
          },
          {
            table: {
              headerRows: 1,
              widths: ["*"],
              body: [
                [
                  this.createTextBlock(
                    `${environment?.app_url}?id=${data.evaluationId}`,
                    13,
                    "left",
                    "#000",
                    true,
                  ),
                ],
              ],
            },
          },
          {
            columns: [
              [
                this.createTextBlock(
                  "Geben Sie Ihren Zugangscode ein und Sie gelangen direkt auf die Umfrage.",
                  13,
                  "left",
                  "#000",
                  true,
                ),
              ],
              [
                {
                  table: {
                    headerRows: 1,
                    widths: ["*"],
                    body: [
                      [{ text: item.code, bold: true, alignment: "right" }],
                    ],
                  },
                  margin: [0, 10, 0, 0],
                },
              ],
            ],
          },
          this.createTextBlock(
            "Wir freuen uns auf Ihre Teilnahme und grüßen Sie herzlich.",
          ),
          {
            text: [
              this.createTextBlock(
                `${this.translate.instant("contact")}: `,
                10,
                "left",
                "black",
                true,
              ),
              this.createTextBlock(
                `${data?.fullNamePdf ? `${data?.fullNamePdf}` : ""} ${data?.personPositionPdf || ""} ${data?.contactPersonPdf || ""}`,
              ),
            ],
          },
          // this.createTextBlock(
          //   `${this.translate.instant("contact")}:`,
          //   10,
          //   "left",
          //   "black",
          //   true,
          // ),
          // this.createTextBlock(
          //   `${data?.fullNamePdf ? `${data?.fullNamePdf}` : ""}`,
          // ),
          // this.createColumn("Kontakt:", data?.fullNamePdf || "", true),

          // this.createTextBlock(data?.personPositionPdf || "", 8),
          // this.createTextBlock(data?.farewellTextPdf || ""),
          // this.createTextBlock(data?.contactPersonPdf || ""),
        ],
      );
    });

    //@ts-ignore
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(docDefinition).open();
  }

  public async generateResultPDF(payload: PdfPayloadResult) {
    console.log("filter type names>>>", payload.filtersData.tokenTypeNames);
    payload.togglePdfLoading(true);
    let docDefinition: any = {
      content: [
        // this.getLogoString(),
        this.getGeneralInfoString(
          this.translate.instant("evaluationResultsFor"),
          payload.currentEvaluation?.name || "",
        ),
        this.getGeneralInfoString(
          `${this.translate.instant("nameOfOrganization")}: `,
          payload.organizationTitle || "",
        ),
        this.getSummaryString(
          `${this.translate.instant("startDateSummary")} ${payload.currentEvaluation?.startdate}`,
        ),
        this.getSummaryString(
          `${this.translate.instant("endDateSummary")} ${payload.currentEvaluation?.enddate}`,
        ),
        // this.getSummaryString(`${this.translate.instant('tokenIssuesCustomers')} ${payload.currentEvaluation?.generatedCustomerTokens}`),
        // this.getSummaryString(`${this.translate.instant('tokenIssuedEmployees')} ${payload.currentEvaluation?.generatedEmployeeTokens}`),
        this.getSummaryString(
          `${this.translate.instant("deliveredTokens")} ${payload.evaluationSummary?.["deliveredTokens"]}`,
        ),
        this.getSummaryString(
          `${this.translate.instant("finalResult")} ${payload.evaluationSummary?.["finalResults"]}`,
        ),
        this.getSummaryString(
          `${this.translate.instant("intermediateResults")} ${payload.evaluationSummary?.["intermediateResults"]}`,
        ),
        this.getSummaryString(
          `${this.translate.instant("unusedTokens")} ${payload.evaluationSummary?.["unusedTokens"]}`,
        ),
        this.getSummaryString(
          `${this.translate.instant("usedTokens")} ${payload.evaluationSummary?.["usedTokens"]}`,
        ),
        {
          text: [
            this.createTextBlock(
              `${this.translate.instant("entities")}: `,
              10,
              "left",
              "black",
              true,
            ),
            this.createTextBlock(
              `${this.arrayWithStringToString(payload.filtersData.tokenTypeNames)}`,
            ),
          ],
          margin: [0, 5],
        },
        {
          text: [
            this.createTextBlock(
              `${this.translate.instant("facilities")}: `,
              10,
              "left",
              "black",
              true,
            ),
            this.createTextBlock(
              `${this.arrayWithStringToString(payload.filtersData.facilityNames)}`,
            ),
          ],
          margin: [0, 5],
          pageBreak: "after",
        },
      ],
      images: {
        logo: this.getDefaultLogoForDoc(
          payload?.currentEvaluation?.logo || DEFAULT_LOGO,
        ),
      },
      styles: {
        sectionHeader: {
          bold: true,
          decoration: "underline",
          fontSize: 14,
          margin: [0, 15, 0, 15],
        },
      },
    };

    setTimeout(async () => {
      try {
        for (const category of payload?.categories) {
          const index = payload?.categories.indexOf(category);
          docDefinition.content.push({
            text: category?.title,
            fontSize: 16,
            alignment: "center",
            color: "#000",
            margin: [0, 20],
            pageBreak: index === 0 ? "none" : "before",
          });
          const graphDataAllToRender = category?.graphDataAll?.filter(
            (el) => el?.type !== "table",
          );
          for (let i = 0; i < graphDataAllToRender?.length; i += 2) {
            // const graphItemData1 = await html2canvas(document.getElementById(`plot${i}`) as HTMLElement);
            let reportGraph;
            try {
              reportGraph = this.plotlyService.getInstanceByDivId(
                `plot${category?.id}${i}`,
              );
            } catch (error) {
              console.log("eror", error);
              continue;
            }
            const plotly = await this.plotlyService.getPlotly();
            const graphItemData1 = await plotly.toImage(
              reportGraph,
              {
                format: "png",
                width: 900,
                height: 700,
                filename: "test.png",
              },
              { format: "png", height: 700, width: 900 },
            );
            docDefinition.images[`plot${category?.id}${i}`] = graphItemData1;
            docDefinition.content.push({
              image: `plot${category?.id}${i}`,
              alignment: "center",
              width: 540,
              margin: [0, 20],
            });
            if (i + 1 < graphDataAllToRender?.length) {
              // const graphItemData2 = await html2canvas(document.getElementById(`plot${i + 1}`) as HTMLElement);
              const reportGraph = this.plotlyService.getInstanceByDivId(
                `plot${category?.id}${i + 1}`,
              );

              const plotly = await this.plotlyService.getPlotly();
              const graphItemData2 = await plotly.toImage(
                reportGraph,
                {
                  format: "png",
                  width: 900,
                  height: 700,
                  filename: "test.png",
                },
                { format: "png", height: 700, width: 900 },
              );
              docDefinition.images[`plot${category?.id}${i + 1}`] =
                graphItemData2;
              docDefinition.content.push({
                image: `plot${category?.id}${i + 1}`,
                width: 540,
                alignment: "center",
                margin: [0, 20],
                pageBreak:
                  i + 2 < graphDataAllToRender?.length ? "after" : "none",
              });
            }
          }
        }

        const bodyOpenAnswers: any = [];
        bodyOpenAnswers.push(
          payload.columnsOpenAnswers.map((column) => column?.title),
        );

        payload.dataSourceOpenAnswers.forEach((data) => {
          const dataRow: any = [];
          payload.columnsOpenAnswers.forEach((column) => {
            const answer = data[column.name] || "Nicht beantwortet";
            dataRow.push(answer);
          });
          bodyOpenAnswers.push(dataRow);
        });
        docDefinition.content.push({
          pageBreak: "before",
          text: "Anmerkungen",
          fontSize: 10,
          bold: true,
          alignment: "center",
          color: "#000",
          margin: [0, 7],
        });
        docDefinition.content.push({
          table: {
            headerRows: 1,
            body: bodyOpenAnswers,
          },
          fontSize: 8,
        });

        const bodyGeneral: any = [];
        bodyGeneral.push(payload?.columnsGeneral);

        payload.dataSourceGeneral?.forEach((data) => {
          const dataRow: any = [];

          payload.columnsGeneral?.forEach((column) => {
            //@TODO very strange behaviour. fix later
            const key = typeof column === "object" ? column?.text : column;
            dataRow.push({
              text: data[key],
              bold: this.checkCategoryByTitle(data[key]),
            });
          });
          bodyGeneral.push(dataRow);
        });
        docDefinition.content.push({
          pageBreak: "before",
          text: "Allgemeine Ergebnisse",
          fontSize: 10,
          bold: true,
          alignment: "center",
          color: "#000",
          margin: [0, 7],
        });
        const newGeneralData = bodyGeneral.map((arrayWithArrays, index) => {
          return arrayWithArrays.map((arrayWithItems, i) => {
            const textForCell =
              index === 0 ? arrayWithItems : arrayWithItems.text;

            let result: any = {
              text: textForCell,
              bold: index !== 0 ? arrayWithItems.bold : true,
            };

            if (!(i == 0 || i === arrayWithArrays?.length - 1)) {
              const totalAverage = Number(arrayWithArrays?.at(-1)?.text);
              const currentValue = Number(arrayWithItems?.text);

              if (
                isNaN(totalAverage) ||
                isNaN(currentValue) ||
                !arrayWithArrays?.at(-1)?.text
              ) {
                //do nothing
              } else {
                let color = "#000";
                if (currentValue > totalAverage) {
                  color = "#118303";
                } else if (currentValue < totalAverage) {
                  color = "#f30707";
                } else if (currentValue === totalAverage) {
                  color = "#0f07f3";
                }
                result = {
                  ...result,
                  color,
                };
              }
            }
            return result;
          });
        });

        docDefinition.content.push({
          table: {
            headerRows: 1,
            body: newGeneralData,
          },
          fontSize: 4,
        });
        console.log("payload", docDefinition);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        payload.togglePdfLoading(false);
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.createPdf(docDefinition).open();
        // window.open(URL.createObjectURL(pdf.output('blob')));
      }
    }, 0);
  }

  private getGeneralInfoString(introduction: string, entity: string) {
    return {
      text: `${introduction} ${entity}`,
      fontSize: 16,
      alignment: "center",
      color: "#000",
      margin: [0, 20],
      bold: true,
    };
  }

  private getSummaryString(text: string, isLast?: boolean) {
    return {
      text,
      margin: [0, 10],
      fontSize: 12,
      pageBreak: isLast ? "after" : "none",
    };
  }

  private getDefaultLogoForDoc(logo: string) {
    return { url: logo || DEFAULT_LOGO };
  }

  private formatDate(date: string) {
    return moment(date).format("DD.MM.YYYY");
  }

  private arrayWithStringToString(array: Array<string>) {
    return array.toString().replaceAll(",", ", ");
  }

  // private getLogoString() {
  //   return { image: "logo", width: 200, alignment: "center", margin: [0, 20] };
  // }

  private checkCategoryByTitle(text: string) {
    let textForComparing = "";

    this.translate.get("category").subscribe((translation: string) => {
      textForComparing = translation;
    });

    return text.includes(textForComparing);
  }
}
